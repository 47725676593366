<template>
  <div class="facilityBox" v-loading="flag.dialogLoading || flag.payStatus == 0">
    <div v-show="!flag.invoiceProgressDialog">
      <!-- 服务商已开过发票 -->
      <div style="z-index: 99999">
        <!-- 服务商已开过发票dialog -->
        <el-dialog class="pending" title="这批运单已开过发票，请勿重复操作" :visible.sync="flag.invoicing" width="700px"
          :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" center>
          <div style="text-align: center">
            <el-button type="primary" size="mini" @click="goBack">确定</el-button>
            <el-button type="primary" size="mini" @click="downloadTaxationExcel" :loading="flag.downloadTaxationExcelIng">
              下载税务表格
            </el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 货主未付款 -->
      <div v-if="flag.payStatus == 0">
        <!-- 货主未付款dialog -->
        <el-dialog class="pending" :visible.sync="flag.noPayedDialig" width="500px" :show-close="false"
          :close-on-press-escape="false" :close-on-click-modal="false" center>
          <p style="display: flex; align-items: center; justify-content: center">
            <i class="el-icon-warning" style="color: #e6a23c; font-size: 24px" />货主付款中，请等待...
          </p>
          <p>您可以线下联系货主，加快进度</p>
          <div style="text-align: center">
            <el-button type="primary" size="mini" @click="flag.showGiveUpInvoice = true">放弃开票</el-button>
            <el-button type="primary" size="mini" @click="waitPatiently">耐心等待</el-button>
            <el-button type="primary" size="mini" @click="downBillsDetails" :disabled="flag.downBillsDetailsIng">下载税务表格
            </el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 货主付款中 -->
      <div v-if="flag.payStatus == 4">
        <!-- 货主付款中dialog 改成分账中 -->
        <el-dialog class="pending" :visible.sync="flag.noPayedDialig" width="500px" :show-close="false"
          :close-on-press-escape="false" :close-on-click-modal="false" center>
          <p style="margin: 8px 0 32px 0; text-align: center">
            {{ flag.ledgerText }}
          </p>
          <div style="text-align: center">
            <el-button type="primary" size="mini" @click="goBack">确认</el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 主体部分 -->
      <div class="bodyArea" v-if="flag.payStatus == 1">
        <div class="title">运单信息进度</div>
        <!-- 步骤条 -->
        <Step :activeList="[1, 1, 1, 1]" :role="3"></Step>
        <!-- 货主已付款且分账成功 -->
        <div class="agree">
          <!-- 第一步 提示面板 -->
          <div class="one" v-if="flag.step == 1" @click="flag.step = 2">
            <!-- 货主已付款dialog -->
            <el-dialog class="payed" title="货主已付款，请上传发票信息" :visible.sync="flag.payedDialig" width="700px"
              :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" center>
              <p>备注：{{ KPRemark }}</p>
              <p>
                请您根据结算单与运单详情，合理优化发票面额组合，开具发票后，将发票电子文件上传。
              </p>
              <p style="color: red">注：电子文件或电子照片保持清晰完整！</p>
              <div style="text-align: center">
                <el-button type="primary" size="mini" @click="flag.payedDialig = false">确定</el-button>
              </div>
            </el-dialog>
          </div>
          <!-- 第二步 -->
          <div class="two" v-show="flag.step == 1 || flag.step == 2">
            <!-- 温馨提示 -->
            <el-alert style="margin: 8px 0 5px 0" type="warning" :closable="false"
              description="温馨提示：1.您可以手动组合多条运单，将其开到一张发票中；2.自动组合会将多条运单关联，使其运单总金额小于等于发票面额；3.如一条运单的总金额大于发票面额，您可以上传多张发票；">
            </el-alert>
            <!-- 顶部信息面板 -->
            <div class="topInfo">
              <!-- 选择发票面额 -->
              <div>
                <span>发票面额：</span>
                <el-select class="select" v-model="facaValue" :disabled="currentTable1dataSelectedCount > 0"
                  placeholder="请选择发票面额">
                  <el-option v-for="item in facaValueList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <!-- 当前组合 -->
              <div class="currentAllFacaValue">
                <span style="
                    display: inline-block;
                    margin: 8px 0 16px 0;
                    width: 65px;
                  ">当前组合 :</span>
                <div class="box">
                  <!-- <span style="display:inline-block;">运费：{{currentAllFacaValue.Amount}}</span> -->
                  <span style="display: inline-block">运费：/</span>
                  <span style="display: inline-block">服务费/税费：{{ currentAllFacaValue.Taxes }}</span>
                  <span style="display: inline-block">运单总金额：{{ currentAllFacaValue.TotalAmount }}</span>
                  <span style="display: inline-block">发票数量：{{ currentAllFacaValue.Count }}</span>
                </div>
              </div>
            </div>
            <el-checkbox v-model="isAllChecked" @change="checkedAll"
              style="margin: 0px 0px 10px 10px; font-weight: bold">全选所有运单</el-checkbox>
            <el-table v-loading="flag.dialogLoading" :header-cell-class-name="cellClass" :data="currentTable1data"
              @select="currentTable1dataSelectedChange" @select-all="currentTable1dataSelectedChange"
              ref="currentTable1dataRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
              <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
              <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="waybillType" label="业务类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200">
                <template slot-scope="scope">
                  <div style="color: blue; cursor: pointer" @click="openDerverInfo(scope.row.DriverUserID)">
                    {{ scope.row.DriverIDCard }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200"
                show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200">
                <template slot-scope="scope">
                  <div style="color: blue; cursor: pointer" @click="openDerverInfo(scope.row.PayeeUserID)">
                    {{ scope.row.PayeeIDCard }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div style="color: blue; cursor: pointer" @click="openDerverInfo(scope.row.LeaderUserID)">
                    {{ scope.row.LeaderIDCard }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="LicensePlate" label="车牌" width="200">
                <template slot-scope="scope">
                  <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
                    {{ scope.row.LicensePlate }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Start_addrees" label="起始地" width="200" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                    {{ scope.row.Start_addrees }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="Arrivals" label="到达地" width="200" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                    {{ scope.row.Arrivals }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
                show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.Driver_transportation_price | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="amount_count" label="运费" width="200">
                <template>
                  <div style="color: #ff8b17">
                    /
                    <!-- {{scope.row.amount_count | formatMoney}} -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.ExpensesOfTaxation | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="TotalAmountIncludeTaxes" label="运单总金额" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.TotalAmountIncludeTaxes | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200" v-if="(flag.waybillType == '02' && flag.taskSceneTypeID == '3') ||
                $entrustSceneIds.includes(Number(flag.taskSceneTypeID))
                ">
                <template>
                  <div style="color: #ff8b17">
                    <!-- {{scope.row.Entrusted_Collection_Amount | formatMoney}} -->
                    /
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="TaxesDZ" label="委托代开服务费/税费" width="200" v-if="(flag.waybillType == '02' && flag.taskSceneTypeID == '3') ||
                $entrustSceneIds.includes(Number(flag.taskSceneTypeID))
                ">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.TaxesDZ | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="AmountDZ" label="委托代开运单总金额" width="200" v-if="(flag.waybillType == '02' && flag.taskSceneTypeID == '3') ||
                $entrustSceneIds.includes(Number(flag.taskSceneTypeID))
                ">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.AmountDZ | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200"
                show-overflow-tooltip></el-table-column>
              <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="PayMethod" label="支付方式" width="200" show-overflow-tooltip>
              </el-table-column>
              <!-- <el-table-column align="center" prop="InvoiceAmountType" label="开票金额" width="200" show-overflow-tooltip>
              </el-table-column> -->
              <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination background class="pagination1" @current-change="handleCurrentChange1"
              :current-page.sync="pagination1.page" :page-size="pagination1.pagesize" :page-sizes="[10, 30, 50, 100]"
              @size-change="pagination1SizeChange" layout="total,sizes,prev, pager, next, jumper"
              :total="pagination1.total">
            </el-pagination>
            <!-- 按钮组 -->
            <div style="text-align: center">
              <el-button type="primary" @click="goBack"> 返回待办</el-button>
              <el-button type="primary" @click="packaging" :disabled="flag.packaging">计入1个发票包</el-button>
              <el-button type="primary" @click="manualComposition">手动组合</el-button>
              <el-button type="primary" v-show="flag.showCompositionBtn" :loading="flag.compositioning"
                :disabled="!flag.waybillType ? true : false" @click="flag.chooseInvoiceDialig = true">自动组合</el-button>
              <el-button type="primary" @click="downBillsDetails" :disabled="flag.downBillsDetailsIng">下载税务表格
              </el-button>
            </div>
            <!-- 车辆信息dialog -->
            <el-dialog width="1300px" title="车辆信息" :visible.sync="flag.showVehicleInfo" append-to-body>
              <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
            </el-dialog>
            <!-- 司机信息组件 -->
            <el-dialog width="1300px" title="司机信息" :visible.sync="flag.showDriverInfo" append-to-body>
              <DriverInfo :driverInfo="driverInfo" />
            </el-dialog>
          </div>
          <!-- 第三步 -->
          <div class="three" v-show="flag.step == 3">
            <!-- 温馨提示 -->
            <el-alert style="margin-bottom: 10px" type="warning"
              description="温馨提示：1.组合完成后，将发票上传（电子发票、纸质发票扫描件/照片）；2.如果OCR识别的发票号、开票日期有误，可以编辑修改；3.对组合结果不满意，点击【重新组合】可以返回上一步；"
              :closable="false">
            </el-alert>
            <!-- 合计 -->
            <div class="settleBox">
              <el-row>
                <el-col :span="5">开票申请时间：<strong>{{
                  wayBillTotal.JsDateTime
                }}</strong></el-col>
                <el-col :span="5">运单数量：<strong>{{
                  wayBillTotal.WayBillCount
                }}</strong></el-col>
                <el-col :span="5">运费(元)：/</el-col>
                <el-col :span="9">服务费/税费(元)：<strong>￥{{ wayBillTotal.TotalTax }}</strong></el-col>
              </el-row>
              <el-row>
                <el-col :span="5">运单总金额(元)：<strong>￥{{ wayBillTotal.TotalAmount }}</strong></el-col>
                <el-col :span="5">货主单位：<strong>{{
                  consign.InvoiceTitleName
                }}</strong></el-col>
                <el-col :span="5">货主税号：<strong>{{
                  consign.InvoiceTitleCreditCode
                }}</strong></el-col>
                <el-col :span="9">货主地址：<strong>{{
                  consign.InvoiceTitleAddress
                }}</strong></el-col>
              </el-row>
              <el-row>
                <el-col :span="5">货主电话：<strong>{{
                  consign.InvoiceTitlePhone
                }}</strong></el-col>
                <el-col :span="5">货主账号：<strong>{{
                  consign.InvoiceTitleBankerNumber
                }}</strong></el-col>
                <el-col :span="5" v-if="consign.InvoiceKindTec">专票普票：<strong>运费-{{ consign.InvoiceKind }} 技术服务费-{{
                  consign.InvoiceKindTec
                }}</strong></el-col>
                <el-col :span="5" v-else>专票普票：<strong>{{ consign.InvoiceKind }}</strong></el-col>
                <el-col :span="9">货主开户行：<strong>{{
                  consign.InvoiceTitleBankerName
                }}</strong></el-col>
              </el-row>
              <el-row>
                <el-col :span="5">支付方式：<strong>{{ consign.PayType }}</strong></el-col>
                <!-- <el-col :span="5" v-if="flag.waybillType == '02' || flag.waybillType == '05'">开票金额：<strong>{{
                  consign.InvoiceAmoutType
                }}</strong>
                </el-col> -->
              </el-row>
            </div>
            <div style="margin: 8px 0px; display: flex; align-items: center">
              <el-button type="primary" style="float: right; margin-right: 10px" @click="downloadTaxationExcel"
                :loading="flag.downloadTaxationExcelIng">下载税务表格
              </el-button>
              <span style="margin-left: 16px">发票状态：</span>
              <el-select @change="changeType" v-model="searchForm.type" placeholder="请选择发票状态" style="width: 160px">
                <el-option v-for="item in invoiceStatusList" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
              </el-select>
              <span style="margin-left: 16px">开票类型：</span>
              <el-select @change="changeType" v-model="searchForm.FeeType" placeholder="请选择开票类型" style="width: 160px">
                <el-option label="全部" value=""></el-option>
                <el-option label="运费" value="0"></el-option>
                <el-option label="技术服务费" value="1"></el-option>
              </el-select>
              <div v-if="wayBillTotal.bAuto">
                <span style="margin-left: 16px">发票项目：</span>
                <el-input placeholder="请输入内容" v-model="invoiceName" style="width: 260px">
                  <img slot="suffix" src="@/assets/image/question.png" style="
                      width: 14px;
                      height: 14px;
                      margin-top: 12px;
                      margin-right: 3px;
                    " @click="flag.questionPreviewDialog = true" />
                </el-input>
                <el-button type="primary" @click="updateInvoiceName" style="margin-left: 10px">保存
                </el-button>
                <span style="margin-left: 16px">UKEY信息：</span>
                <el-select v-model="keyId" placeholder="请选择" @change="getInvoiceStore(keyId)" style="width: 530px">
                  <el-option v-for="item in ukeyOptions" :key="item.value" :label="item.label" :value="item.value"
                    value-key="KeyId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 表格 -->

            <el-table :data="tableData2Total" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              @selection-change="handleSelectionChange" ref="multipleTable">
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column fixed="left" align="center" prop="index" label="序号" width="80" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="PrintTaskID" label="发票任务单号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="WayBillType" label="业务类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="SupplierName" label="服务商单位" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="WaybillCount" label="总计运单数量" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Rate" label="费率" width="200">
                <template slot-scope="scope">
                  <p>{{ scope.row.Rate }}%</p>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="CarriageAmount" label="运费" width="200">
                <template>
                  <div style="color: #ff8b17">
                    /
                    <!-- {{scope.row.CarriageAmount | formatMoney}} -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="TaxAmount" label="服务费/税费" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.TaxAmount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="PayTotalAmount" label="运单总金额" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.PayTotalAmount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="FeeTypeName" label="开票类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceAmount" label="发票面额" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.InvoiceAmount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="_invoiceNum" label="发票号码" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="CreateTime" label="开票日期" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceDesc" label="描述" width="300" fixed="right"
                show-overflow-tooltip>
                <template slot-scope="scope" v-if="scope.row.FeeType != '1'">
                  <div :style="{ color: getColor(scope.row) }" style="
                      cursor: pointer;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    " @click="openUpdateReamrk(scope.row)">
                    {{
                      scope.row.InvoiceDesc
                      ? scope.row.InvoiceDesc
                      : "暂无七要素描述"
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" align="center" label="操作" width="280">
                <template slot-scope="scope">
                  <el-button type="primary" @click="_uploadInvoice(scope.row, scope.$index)">查看/上传发票</el-button>
                  <el-button type="primary" @click="autoInvoice(scope.row)" style="margin: 0px 5px 0px 10px"
                    v-if="wayBillTotal.bAuto">预览
                  </el-button>
                  <div style="display: inline-block" v-if="scope.row.AutoStatus != 0">
                    <el-tooltip class="item" effect="dark" :content="scope.row.Remark" placement="top-end">
                      <img src="@/assets/image/fail-icon.png" v-if="scope.row.AutoStatus != 2 &&
                        scope.row.AutoStatus != 20 &&
                        scope.row.AutoStatus != 21 &&
                        scope.row.AutoStatus != 1
                        " />
                      <img v-else src="@/assets/image/success.png" />
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex; align-items: center; margin: 16px 0px">
              <!-- 分页 -->
              <el-pagination background @current-change="handleCurrentChange2" :current-page.sync="pagination2.page"
                :page-sizes="[10, 30, 50, 100]" @size-change="sizeChange" :page-size="pagination2.pagesize"
                layout="total,sizes, prev, pager, next, jumper" :total="pagination2.total">
              </el-pagination>
              <el-radio-group v-model="invoiceTypeChosed" style="margin: 0 auto" v-if="wayBillTotal.bAuto">
                <el-radio :label="it" v-for="(it, idx) in invoiceStoreList" :key="idx">{{ it.Describe }} 剩余票量：<span
                    style="color: #ff2323">{{
                      it.RemainNum
                    }}</span></el-radio>
              </el-radio-group>
            </div>
            <!-- 右下角按钮-->
            <div style="width: 100%; text-align: center" v-if="tableData2Total.length > 0">
              <el-button type="primary" @click="goBack">返回待办</el-button>
              <el-button type="primary" @click="goStep(2)">重新组合</el-button>
              <el-button type="primary" @click="printDataStore" :loading="flag.printDataStoreIng">暂存</el-button>
              <el-button type="primary" @click="invoicing">开票完成</el-button>
              <el-button type="primary" @click="autoInvoice" :disabled="!tableData2Total.length || !invoiceStoreList.length
                " v-if="wayBillTotal.bAuto">一键开票
              </el-button>
              <el-button type="primary" @click="freshList" :disabled="flag.isFresh || !invoiceStoreList.length"
                v-if="wayBillTotal.bAuto">刷新
              </el-button>
              <!-- <el-button type="primary" @click="downloadTaxationExcel" :loading="flag.downloadTaxationExcelIng">下载税务表格
            </el-button> -->
            </div>

            <!-- 上传发票dialog -->
            <el-dialog width="1500px" height="600px" :visible.sync="flag.uploadInvoiceDialog"
              :close-on-press-escape="false" :close-on-click-modal="false">
              <el-tabs v-model="flag.activeTab">
                <el-tab-pane label="电子发票" name="0">
                  <vxe-table border resizable show-overflow :data="electronInvoiceList" v-if="flag.activeTab == '0'"
                    :edit-config="{ trigger: 'click', mode: 'cell' }" @checkbox-change="selectChangeElectronInvoice"
                    @checkbox-all="selectChangeElectronInvoice" class="update-special-table">
                    <!-- <vxe-column type='checkbox' width='60'>
                    </vxe-column> -->
                    <vxe-table-column align="center" field="index" title="序号" width="60"></vxe-table-column>
                    <vxe-table-column align="center" field="InvoiceURL" title="发票图片" width="100">
                      <template #default="{ row }">
                        <el-image style="width: 40px; height: 40px; margin-top: 5px" :src="row.InvoiceImgUrl"
                          :preview-src-list="[row.InvoiceImgUrl]"
                          v-if="row.InvoiceImgUrl && isImage(row.InvoiceImgUrl)"></el-image>
                        <img src="@/assets/image/other_icon.png" style="width: 40px;height:40px" v-else />
                      </template>
                    </vxe-table-column>
                    <vxe-table-column align="center" field="PrintTaskID" title="打票单号（必填）" width="150"
                      :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                    <vxe-table-column align="center" field="InvoiceCode" title="发票代码（必填）" width="150"
                      :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                    <vxe-table-column align="center" field="InvoiceNumber" title="发票号码（必填）" width="150"
                      :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                    <vxe-table-column align="center" field="CreateTime" title="开具日期（必填）" width="150"
                      :edit-render="{ autoselect: true }">
                      <template #edit="{ row }">
                        <el-input v-model="row.CreateTime" type="date" value-format="yyyy-MM-dd" />
                      </template>
                    </vxe-table-column>
                    <vxe-table-column align="center" field="InvoiceAmount" title="开具金额（必填）" width="150"
                      :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                    <vxe-table-column align="center" field="InvoiceTotalAmount" title="价税合计" width="100"
                      :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                    <vxe-table-column align="center" field="CheckCode" title="校验码" width="150"
                      :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                    <vxe-table-column align="center" field="StatusDesc" title="状态" width="150"></vxe-table-column>
                    <vxe-table-column align="center" title="操作" width="350">
                      <template #default="{ row }">
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(1, row)" v-if="!row.InvoiceNumber &&
                          !row.InvoiceSerialNum &&
                          row.AutoStatus != 0
                          ">重开</el-button>
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(1, row)" v-if="!row.InvoiceNumber &&
                          !row.InvoiceSerialNum &&
                          row.AutoStatus == 0
                          ">开票</el-button>
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(2, row)" v-if="row.InvoiceNumber"
                          disabled>冲红</el-button>
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(3, row.pdfUrl)"
                          v-if="row.InvoiceNumber" :disabled="!row.pdfUrl">下载pdf</el-button>
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(3, row.ofdUrl)"
                          v-if="row.InvoiceNumber" :disabled="!row.ofdUrl">下载ofd</el-button>
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(4, row)"
                          v-if="!row.InvoiceNumber && row.InvoiceSerialNum">查询</el-button>
                        <!-- <el-button type="primary" size="mini" @click="batchInvoiceAction(5, row)">重新交付</el-button> -->
                        <el-button type="primary" size="mini" @click="batchInvoiceAction(6, row)"
                          v-if="row.InvoiceNumber && row.AutoStatus == 1" :disabled="row.Status == 3 || row.Status == 4">
                          作废
                        </el-button>
                      </template>
                    </vxe-table-column>
                  </vxe-table>
                  <!-- 分页 -->
                  <el-pagination background @current-change="handleCurrentChangeElectron" style="margin-top: 8px"
                    :current-page.sync="paginationElectron.page" :page-size="paginationElectron.pagesize"
                    layout="total, prev, pager, next, jumper" :total="paginationElectron.total">
                  </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="纸质发票" name="1">
                  <div style="height: 60vh; overflow-y: scroll">
                    <!-- 提示 -->
                    <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
                      <span slot="title">
                        <span>温馨提示：1.上传发票，将自动OCR识别发票信息；2.上传异常、需手动填写发票3项信息，平台将人工审核，并在全国增值税发票查验平台校验真伪</span><br />
                      </span>
                    </el-alert>
                    <input type="file" style="display: none" @change="handleInvoiceUpload" multiple ref="invoice" />
                    <input type="file" style="display: none" @change="handleSpecialInvoiceUpload" multiple
                      ref="specialInvoice" />
                    <input type="file" style="display: none" @change="handleVoucherUpload" ref="voucher" />

                    <!-- 常见发票 -->
                    <!-- <strong style="font-size: 15px; font-weight: 600;">常见发票</strong>
                    <el-button type="primary" style="margin: 16px 16px;" @click="$refs.invoice.click()"
                      :loading="flag.uploadNormalInvoice" icon="el-icon-upload2">批量上传</el-button>
                    <span>注明：最多一次可上传10张</span>
                    <div class="uploadImgWrap">
                      <div class="item" v-for="(item, index) in invoiceImgList" :key="index">
                        <i class="delete el-icon-delete" @click="handleInvoiceDelete(index)"></i>
                        <el-image class="img" :src="item.picUrl" :preview-src-list="[item.picUrl]">
                          <div slot="error" class="image-slot"
                            style="display: flex; justify-content: center; margin-top: 30px;">
                            <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                          </div>
                        </el-image>
                        <el-tooltip :content="item.picUrl">
                          <p @click="downloadVoucher(item.picUrl)"
                            style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue">
                            {{
                              item.picUrl
                            }}</p>
                        </el-tooltip>
                      </div>
                    </div> -->
                    <!-- 分页 -->
                    <!-- <el-pagination background @current-change="handleCurrentChange3" :current-page.sync="pagination3.page"
                      :page-size="pagination3.pagesize" layout="total, prev, pager, next, jumper"
                      :total="pagination3.total">
                    </el-pagination> -->

                    <hr style="margin-top: 16px" />

                    <span style="font-size: 15px; font-weight: 600">上传发票</span>
                    <el-button type="primary" style="margin: 16px 16px" @click="$refs.specialInvoice.click()"
                      :loading="flag.uploadSpecialInvoice" icon="el-icon-upload2">批量上传</el-button>
                    <span>注明：最多一次可上传10张</span>

                    <el-button type="primary" style="margin: 16px 16px" @click="NoSpecialInvoiceListonClick"
                      :loading="flag.uploadSpecialInvoice">上传无图发票</el-button>
                    <!-- 表格 -->
                    <vxe-table border resizable show-overflow :data="specialInvoiceList"
                      :edit-config="{ trigger: 'click', mode: 'cell' }" class="special-table" key="specialInvoice"
                      v-if="flag.activeTab == '1'">
                      <vxe-table-column align="center" field="index" title="序号" width="14%"></vxe-table-column>
                      <vxe-table-column align="center" field="picUrl" title="发票图片" width="14%">
                        <template #default="{ row }">
                          <el-image style="width: 40px; height: 40px; margin-top: 5px" :src="row.picUrl"
                            :preview-src-list="[row.picUrl]" v-if="row.picUrl && isImage(row.picUrl)"></el-image>
                          <img src="@/assets/image/other_icon.png" style="width: 40px;height:40px" v-else />
                          <!-- <span v-else style="color: red">已由系统回填信息</span> -->
                        </template>
                      </vxe-table-column>
                      <vxe-table-column align="center" field="invoiceCode" title="发票代码（必填）"
                        :edit-render="{ name: 'input', autoselect: true }" width="14%"></vxe-table-column>
                      <vxe-table-column align="center" field="invoiceNum" title="发票号码（必填）"
                        :edit-render="{ name: 'input', autoselect: true }" width="14%"></vxe-table-column>
                      <vxe-table-column align="center" field="CreateTime" title="开票日期（必填）"
                        :edit-render="{ autoselect: true }" width="14%">
                        <template #edit="{ row }">
                          <el-input v-model="row.CreateTime" type="date" value-format="yyyy-MM-dd" />
                        </template>
                      </vxe-table-column>
                      <vxe-table-column align="center" field="IsOcr" title="是否识别" width="14%">
                        <template #default="{ row }">
                          <el-tag :type="row.IsOcr ? 'success' : 'danger'">{{
                            row.IsOcr ? "是" : "否"
                          }}</el-tag>
                        </template>
                      </vxe-table-column>
                      <!-- <vxe-table-column align="center" field="money" title="开具金额（必填）" width="150"
                        :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                      <vxe-table-column align="center" field="total" title="价税合计" width="150"
                        :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                      <vxe-table-column align="center" field="verifyCode" title="校验码" width="150"
                        :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
                      <vxe-table-column align="center" title="操作" width="15%">
                        <template #default="{ row }">
                          <el-button type="danger" @click="deleteSpecialInvoiceDelete(row.index)">删除</el-button>
                        </template>
                      </vxe-table-column>
                    </vxe-table>
                    <!-- 分页 -->
                    <el-pagination background @current-change="handleCurrentChange4" style="margin-top: 8px"
                      :current-page.sync="pagination4.page" :page-size="pagination4.pagesize"
                      layout="total, prev, pager, next, jumper" :total="pagination4.total">
                    </el-pagination>
                  </div>
                </el-tab-pane>

                <el-tab-pane label="清单" name="2">
                  <div style="height: 60vh; overflow-y: scroll">
                    <!-- 提示 -->
                    <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
                      <span slot="title">
                        <span>温馨提示：上传与发票相关的清单、票据等。</span><br />
                      </span>
                    </el-alert>
                    <el-button type="primary" :loading="flag.uploadingInvoice" @click="$refs.voucher.click()"
                      icon="el-icon-upload2">上传清单</el-button>

                    <!-- 凭证列表 -->
                    <div class="voucherWrap">
                      <div class="item" v-for="(item, index) in voucherList" :key="index">
                        <i class="delete el-icon-delete" @click="handleVoucherDelete(pagination5.page, index)"></i>
                        <el-image style="
                            width: 200px;
                            height: 100px;
                            margin-bottom: 8px;
                          " :src="item.picUrl" :preview-src-list="[item.picUrl]">
                          <div slot="error" class="image-slot">
                            <img style="width: 200px; height: 100px" @click="downloadVoucher(item.picUrl)"
                              :src="ossHost + 'fileDefaultBg.png'" alt="" />
                          </div>
                        </el-image>
                        <p @click="downloadVoucher(item.picUrl)">
                          {{ item.name }}
                        </p>
                      </div>
                    </div>
                    <!-- 分页 -->
                    <el-pagination background @current-change="handleCurrentChange5" style="margin-top: 8px"
                      :current-page.sync="pagination5.page" :page-size="pagination5.pagesize"
                      layout="total, prev, pager, next, jumper" :total="pagination5.total">
                    </el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <!-- 按钮 -->
              <div style="text-align: center; margin-top: 8px">
                <!-- <div v-if="flag.activeTab == 0" style="display: inline-block;margin-right: 10px;">
                  <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="batchInvoiceAction(3)"
                    :disabled="!chosedElectronInvoice.length">全部下载</el-button>
                  <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="batchInvoiceAction(2)"
                    :disabled="!chosedElectronInvoice.length">全部冲红</el-button>
                  <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="batchInvoiceAction(1)"
                    :disabled="!chosedElectronInvoice.length">全部重开</el-button>
                </div> -->
                <el-button type="primary" size="mini" v-if="flag.activeTab != 0" :loading="flag.uploadingInvoice"
                  @click="handleCloseUploadInvoiceDialog">确认</el-button>
              </div>
            </el-dialog>
            <!-- 修改描述dialog -->
            <el-dialog width="600px" :visible.sync="flag.updateRemarkDialog" append-to-body>
              <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" placeholder="请输入七要素描述"
                :maxlength="consign.InvoiceKind == '平台普票' ? '87' : '105'" v-if="JSON.stringify() != '{}'"
                v-model="currentItem.InvoiceDesc" show-word-limit></el-input>
              <div style="text-align: center; margin-top: 16px">
                <el-button style="margin-right: 16px" type="primary" size="mini" @click="updateRemark">确认</el-button>
                <el-button type="primary" size="mini" @click="flag.updateRemarkDialog = false">取消</el-button>
              </div>
            </el-dialog>
          </div>

          <!-- 第四步 -->
          <div class="four" v-show="flag.step == 4">
            <el-alert style="margin: 8px 0 8px 0" type="warning" :closable="false"
              description="温馨提示：1.如果你上传错了发票，可以点击【修改开票】，返回上一步，更新发票文件；2.发票发送至货主后，等待货主核实；"></el-alert>
            <!-- 合计 -->
            <div class="settleBox">
              <el-row>
                <el-col :span="5">开票申请时间：<strong>{{
                  wayBillTotal.JsDateTime
                }}</strong></el-col>
                <el-col :span="5">运单数量：<strong>{{
                  wayBillTotal.WayBillCount
                }}</strong></el-col>
                <el-col :span="5">运费(元)：/</el-col>
                <el-col :span="9">服务费/税费(元)：<strong>￥{{ wayBillTotal.TotalTax }}</strong></el-col>
              </el-row>
              <el-row>
                <el-col :span="5">运单总金额(元)：<strong>￥{{ wayBillTotal.TotalAmount }}</strong></el-col>
                <el-col :span="5">货主单位：<strong>{{
                  consign.InvoiceTitleName
                }}</strong></el-col>
                <el-col :span="5">货主税号：<strong>{{
                  consign.InvoiceTitleCreditCode
                }}</strong></el-col>
                <el-col :span="9">货主地址：<strong>{{
                  consign.InvoiceTitleAddress
                }}</strong></el-col>
              </el-row>
              <el-row>
                <el-col :span="5">货主电话：<strong>{{
                  consign.InvoiceTitlePhone
                }}</strong></el-col>
                <el-col :span="5">货主账号：<strong>{{
                  consign.InvoiceTitleBankerNumber
                }}</strong></el-col>
                <el-col :span="5" v-if="consign.InvoiceKindTec">专票普票：<strong>运费-{{ consign.InvoiceKind }} 技术服务费-{{
                  consign.InvoiceKindTec
                }}</strong></el-col>
                <el-col :span="5" v-else>专票普票：<strong>{{ consign.InvoiceKind }}</strong></el-col>
                <el-col :span="9">货主开户行：<strong>{{
                  consign.InvoiceTitleBankerName
                }}</strong></el-col>
              </el-row>
              <el-row>
                <el-col :span="5">支付方式：<strong>{{ consign.PayType }}</strong></el-col>
                <!-- <el-col :span="5" v-if="flag.waybillType == '02' || flag.waybillType == '05'">开票金额：<strong>{{
                  consign.InvoiceAmoutType
                }}</strong>
                </el-col> -->
              </el-row>
            </div>
            <!-- 表格 -->
            <el-table :data="tableData2Total" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
              <el-table-column fixed="left" align="center" prop="index" label="序号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="PrintTaskID" label="发票任务单号" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="WayBillType" label="业务类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="SupplierName" label="服务商单位" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="WaybillCount" label="总计运单数量" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="Rate" label="费率" width="200">
                <template slot-scope="scope">
                  <p>{{ scope.row.Rate }}%</p>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="CarriageAmount" label="运费" width="200">
                <template>
                  <div style="color: #ff8b17">
                    /
                    <!-- {{scope.row.CarriageAmount | formatMoney}} -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="TaxAmount" label="服务费/税费" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.TaxAmount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="PayTotalAmount" label="运单总金额" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.PayTotalAmount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="InvoiceAmount" label="发票面额" width="200">
                <template slot-scope="scope">
                  <div style="color: #ff8b17">
                    {{ scope.row.InvoiceAmount | formatMoney }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="_invoiceNum" label="发票号码" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column align="center" prop="CreateTime" label="开票日期" width="200" show-overflow-tooltip>
              </el-table-column>
              <el-table-column fixed="right" align="center" label="操作" width="250">
                <template slot-scope="scope">
                  <el-button @click="open_detail(scope.row)" type="primary" size="mini" title="查看详情">查看详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination background style="margin-top: 16px" @current-change="handleCurrentChange2"
              :current-page.sync="pagination2.page" :page-size="pagination2.pagesize"
              layout="total, prev, pager, next, jumper" :total="pagination2.total">
            </el-pagination>
            <input type="file" style="display: none" @change="handleUploadData" ref="uploadData" accept=".xlsx" />
            <!-- 右下角按钮-->
            <div style="width: 100%; text-align: center">
              <el-button type="primary" @click="goBack">返回待办</el-button>
              <el-button type="primary" @click="flag.step = 3" :disabled="flag.sendInvoice ? true : false">修改开票
              </el-button>
              <el-button type="primary" @click="flag.sendBossDialog = true" :disabled="flag.sendInvoice ? true : false">
                发送至货主</el-button>
              <el-button type="warning" @click="$refs.uploadData.click()">上传开票表格</el-button>
              <el-button type="warning" @click="DownLoadInvoiceExcel">下载开票表格模版</el-button>
            </div>
            <!-- 查看详情dialog -->
            <el-dialog title="发票详情" :visible.sync="flag.detailDialog" center width="80%">
              <!-- 顶部按钮 -->
              <div style="margin-bottom: 16px; text-align: left">
                <el-button type="primary" size="mini" @click="billDown">下载发票运单表格</el-button>
              </div>
              <!-- 货物承运表格展示 -->
              <el-table v-if="flag.waybillType == '01' && flag.taskSceneTypeID == '1'" v-loading="flag.dialogLoading"
                :data="tableData3" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="500px">
                <el-table-column fixed="left" align="center" prop="index" label="序号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskDatetime" label="申请开票时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.DriverCarryPrice | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
                  <template>
                    <div style="color: #ff8b17">
                      /
                      <!-- {{scope.row.CarriageTotalPrice | formatMoney}} -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="费率" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <p>{{ scope.row.Rate }}%</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.ServiceCharge | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="含税金额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.TotalAmount | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="税率" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <p>{{ scope.row.DefaultRate }}%</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop=" NonTaxTotalAmount" label="不含税金额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.NonTaxTotalAmount | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop=" TaxFee" label="税额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.TaxFee | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="车牌号" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
                      {{ scope.row.CarNumber }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="VehicleType" label="车辆类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                      {{ scope.row.OriginAddress }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                      {{ scope.row.ArriveAddress }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openDerverInfo(scope.row.DriverUserID)">
                      {{ scope.row.DriverIDCard }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="BankerName" label="服务商开户行" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="200" show-overflow-tooltip>
                </el-table-column>
              </el-table>
              <!-- 土石方表格展示 -->
              <el-table v-if="flag.waybillType == '01' && flag.taskSceneTypeID == '6'" v-loading="flag.dialogLoading"
                :data="tableData3" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="500px">
                <el-table-column fixed="left" align="center" prop="index" label="序号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskDatetime" label="申请开票时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.DriverCarryPrice | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
                  <template>
                    <div style="color: #ff8b17">
                      /
                      <!-- {{scope.row.CarriageTotalPrice | formatMoney}} -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="费率" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <p>{{ scope.row.Rate }}%</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.ServiceCharge | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="含税金额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.TotalAmount | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="税率" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <p>{{ scope.row.DefaultRate }}%</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop=" NonTaxTotalAmount" label="不含税金额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.NonTaxTotalAmount | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop=" TaxFee" label="税额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.TaxFee | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="车牌号" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
                      {{ scope.row.CarNumber }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="VehicleType" label="车辆类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                      {{ scope.row.OriginAddress }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                      {{ scope.row.ArriveAddress }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证" width="200">
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openDerverInfo(scope.row.DriverUserID)">
                      {{ scope.row.DriverIDCard }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="BankerName" label="服务商开户行" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="200" show-overflow-tooltip>
                </el-table-column>
              </el-table>
              <!-- 交通运输表格展示 -->
              <el-table v-if="(flag.waybillType == '02' && flag.taskSceneTypeID == '3') ||
                $entrustSceneIds.includes(Number(flag.taskSceneTypeID))
                " v-loading="flag.dialogLoading" :data="tableData3"
                :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="500px">
                <el-table-column fixed="left" align="center" prop="index" label="序号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="200"
                  show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="TaskDatetime" label="申请开票时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.DriverCarryPrice | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
                  <template>
                    <div style="color: #ff8b17">
                      /
                      <!-- {{scope.row.CarriageTotalPrice | formatMoney}} -->
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="费率" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <p>{{ scope.row.Rate }}%</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.ServiceCharge | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: #ff8b17">
                      {{ scope.row.TotalAmount | formatMoney }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="CarNumber" label="车牌号" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
                      {{ scope.row.CarNumber }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="VehicleType" label="车辆类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                      {{ scope.row.OriginAddress }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                      {{ scope.row.ArriveAddress }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DriverIDCard" label="司机身份证" width="200">
                  <template slot-scope="scope">
                    <div style="color: blue; cursor: pointer" @click="openDerverInfo(scope.row.DriverUserID)">
                      {{ scope.row.DriverIDCard }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="200"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="200"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerName" label="服务商开户行" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="200" show-overflow-tooltip>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination background class="invoicePagination" @current-change="handleInvoicePageChange"
                :current-page.sync="invoicePagination.page" :page-size="invoicePagination.pagesize"
                layout="total, prev, pager, next, jumper" :total="invoicePagination.total">
              </el-pagination>
            </el-dialog>
            <!-- 发送至货主dialog -->
            <el-dialog :visible.sync="flag.sendBossDialog" title="发送至货主" width="500px"
              :close-on-click-modal="flag.sendOwnerOperate" :close-on-press-escape="flag.sendOwnerOperate">
              <!-- 提示 -->
              <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
                <span slot="title">
                  <span>温馨提示：</span><br />
                  <span>1.建议您将纸质发票通过物流快递寄给货主，并点击“寄送纸质票”按钮</span><br />
                  <span>2.将快递公司、快递单号告知货主</span><br />
                  <span>3.如与货主协商好，不寄送纸质发票，可以不填写，并点击“仅电子发票”按钮</span><br />
                </span>
              </el-alert>
              <el-form :model="sendBossForm" ref="sendBossForm" label-width="100px">
                <el-form-item label="快递公司" prop="expressName">
                  <el-input v-model="sendBossForm.expressName" placeholder="请输入快递公司名称"></el-input>
                </el-form-item>
                <el-form-item label="快递单号" prop="expressNumber">
                  <el-input v-model="sendBossForm.expressNumber" placeholder="请填写快递单号"></el-input>
                </el-form-item>
              </el-form>
              <!-- 发票选择 -->
              <div style="text-align: center">
                <el-button type="primary" size="mini" @click="sendBoss(1)" :loading="flag.sendBossIng">寄送纸质票</el-button>
                <el-button type="primary" size="mini" @click="sendBoss(2)" :loading="flag.sendBossIng">仅电子发票</el-button>
              </div>
            </el-dialog>
            <!-- 地图dialog -->
            <el-dialog width="800px" title="查看地图" :visible.sync="flag.showMap" append-to-body>
              <!-- 地图组件 -->
              <TXmap style="height: 600px" v-if="flag.showMap" :list="mapList" ref="map"></TXmap>
            </el-dialog>
          </div>
        </div>
      </div>

      <!-- 货主已付款未分账成功 -->
      <el-dialog :visible.sync="flag.showLedgerDialog" width="700px" center :show-close="false"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <p style="margin: 8px 0 32px 0; text-align: center">
          {{ flag.ledgerText }}
        </p>
        <div style="text-align: center">
          <el-button type="primary" size="mini" @click="goBack">确认</el-button>
        </div>
      </el-dialog>
      <!-- 服务商同意货主放弃付款 -->
      <el-dialog :visible.sync="flag.showGiveUpInvoice" width="700px" center>
        <p style="margin: 8px 0">
          1.您点击【放弃】，则不再为该货主开票，本结算单将终止流程。
        </p>
        <p style="margin: 8px 0">2.您的每月最大可开票额度会自动补充。</p>
        <div style="text-align: center">
          <el-button type="primary" size="mini" @click="flag.showGiveUpInvoice = false">取消</el-button>
          <el-button type="primary" size="mini" @click="providerGiveUpInvoice">放弃</el-button>
        </div>
      </el-dialog>
      <!-- 上传运单错误信息dialog -->
      <el-dialog :visible.sync="flag.uploadErrorDialog" title="错误说明" center append-to-body>
        <div style="height: 60vh; overflow-y: scroll">
          <el-table :data="uploadErrorList" :header-cell-class-name="cellClass"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
            <el-table-column align="center" prop="Hint" label="错误信息" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </el-dialog>
      <!-- 点击自动组合弹窗发票选择框 -->
      <el-dialog :visible.sync="flag.chooseInvoiceDialig" title="发票选择" width="570px">
        <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          ">
          <el-radio v-model="combinType" :label="2">司机运单自动组合</el-radio>
          <div>将发票7要素相同的运单计入一个发票包</div>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          ">
          <el-radio v-model="combinType" :label="3">每条运单单独开票</el-radio>
          <div>请确保发票充足，以免开票被驳回</div>
        </div>
        <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          ">
          <el-radio disabled v-model="combinType" :label="4">按车牌号自动组合</el-radio>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancleChooseInvoice">取 消</el-button>
          <el-button type="primary" @click="composition">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="flag.questionPreviewDialog" title="" width="1250px" class="preview-invoice-demo">
        <img src="@/assets/image/invoice-demo.png" />
      </el-dialog>
      <el-dialog :visible.sync="flag.previewInvioceDialog" title="发票预览" width="1200px" height="808px"
        class="preview-dialog">
        <div style="position: relative">
          <div style="
              color: red;
              font-size: 14px;
              position: absolute;
              top: -22px;
              left: 86px;
            ">
            发票预览仅限于核对发票信息是否正确，具体以开具的电子发票为准！
          </div>
          <img src="@/assets/image/invoice-bg.png" width="1168px" height="684px" />
          <div style="left: 180px; top: 138px" class="invoice-con">
            {{ previewInvoiceInfo.buyerInfo.InvoiceTitleName }}
          </div>
          <div style="left: 180px; top: 170px" class="invoice-con">
            {{ previewInvoiceInfo.buyerInfo.InvoiceTitleCreditCode }}
          </div>
          <div style="left: 180px; top: 195px" class="invoice-con">
            {{ previewInvoiceInfo.buyerInfo.InvoiceTitleAddress }}、{{
              previewInvoiceInfo.buyerInfo.InvoiceTitlePhone
            }}
          </div>
          <div style="left: 180px; top: 223px" class="invoice-con">
            {{ previewInvoiceInfo.buyerInfo.InvoiceTitleBankerName }}、{{
              previewInvoiceInfo.buyerInfo.InvoiceTitleBankerNumber
            }}
          </div>
          <div style="left: 32px; top: 293px; width: 258px; text-align: left" class="invoice-con">
            {{ previewInvoiceInfo.InvoiceName }}
          </div>
          <div style="left: 394px; top: 293px; width: 78px; text-align: center" class="invoice-con">
            {{ previewInvoiceInfo.GoodsUnit }}
          </div>
          <div style="left: 472px; top: 295px; width: 70px; text-align: right" class="invoice-con">
            {{ previewInvoiceInfo.count }}
          </div>
          <div style="left: 547px; top: 295px; width: 128px; text-align: center" class="invoice-con">
            {{ previewInvoiceInfo.Price }}
          </div>
          <div style="left: 676px; top: 295px; width: 162px; text-align: right" class="invoice-con">
            {{ previewInvoiceInfo.NonTaxAmount }}
          </div>
          <div style="left: 842px; top: 295px; width: 69px; text-align: right" class="invoice-con">
            {{ previewInvoiceInfo.Rate }}
          </div>
          <div style="left: 916px; top: 295px; width: 223px; text-align: right" class="invoice-con">
            {{ previewInvoiceInfo.TaxFee }}
          </div>
          <div style="left: 676px; top: 462px; width: 162px; text-align: right" class="invoice-con">
            ￥{{ previewInvoiceInfo.NonTaxAmount }}
          </div>
          <div style="left: 300px; top: 491px; width: 30px; text-align: left" class="invoice-con">
            <img src="@/assets/image/wrong.png" />
          </div>
          <div style="left: 330px; top: 492px; width: 600px; text-align: left" class="invoice-con">
            {{ previewInvoiceInfo.NonTaxAmountConvert }}
          </div>
          <div style="left: 973px; top: 492px; width: 168px; text-align: left" class="invoice-con">
            ￥{{
              (
                Number(previewInvoiceInfo.NonTaxAmount) +
                Number(previewInvoiceInfo.TaxFee)
              ).toFixed(2)
            }}
          </div>
          <div style="left: 916px; top: 462px; width: 223px; text-align: right" class="invoice-con">
            ￥{{ previewInvoiceInfo.TaxFee }}
          </div>
          <div style="left: 180px; top: 530px; width: 438px" class="invoice-con">
            {{ previewInvoiceInfo.sellerInfo.InvoiceTitleName }}
          </div>
          <div style="left: 180px; top: 560px; width: 438px" class="invoice-con">
            {{ previewInvoiceInfo.sellerInfo.InvoiceTitleCreditCode }}
          </div>
          <div style="left: 180px; top: 586px; width: 438px" class="invoice-con">
            {{ previewInvoiceInfo.sellerInfo.InvoiceTitleAddress }}、{{
              previewInvoiceInfo.sellerInfo.InvoiceTitlePhone
            }}
          </div>
          <div style="left: 180px; top: 613px; width: 438px" class="invoice-con">
            {{ previewInvoiceInfo.sellerInfo.InvoiceTitleBankerName }}、{{
              previewInvoiceInfo.sellerInfo.InvoiceTitleBankerNumber
            }}
          </div>
          <div style="
              left: 671px;
              top: 524px;
              white-space: inherit;
              height: 113px;
              width: 468px;
            " class="invoice-con">
            {{ previewInvoiceInfo.Remark }}
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="flag.previewInvioceDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitAutoInvoice">一键开票</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 开票中 -->
    <div class="invoicingDialog" v-if="flag.invoiceProgressDialog">
      <el-progress :width="400" type="circle" :stroke-width="20" :percentage="flag.invoiceProgress"></el-progress>
      <p class="desc">开票中，请稍后...</p>
    </div>
  </div>
</template>

<script>
import {
  ImportInvoiceTable,
} from "@/api/finance/account/index";
import {
  billDown,
  RefundWaybill,
  DisInvoices,
  UpdateInvoiceDesc,
  AutoCheckInvoice,
} from "@/api/waybill/waybillManage/index";
import { ownerPays } from "@/api/supplierWaybill/basicWaybill/makeInvoice";
import {
  printDataStore,
  specialInvoice,
  uploadInvoices,
  addInvoiceChecklist,
  providerGiveUpInvoice,
  billsInfoDeatils,
  combinaStatis,
  getDenomination,
  commissionCombinInvoice,
  combinInvoice,
  statisWaybill,
  updateInvoiceStatus,
  getInvoiceDetaleds,
  invoiceTaskDetails,
  earthWorkGetInvoiceDetals,
  manualCombina,
  verifyPrinTaskID,
  autoInvoice,
  GetInvoiceDetails,
  BatchInvoiceAction,
  GetInvoiceName,
  UpdateInvoiceName,
  PreviewInvoice,
  GetInvoiceStore,
  EidtInvoice,
  CompleteVerify
} from "@/api/supplierWaybill/basicWaybill/makeInvoice";
import { getMapPath, getCarInfo, getDriverDetails } from "@/api/common/common";
import { GetUKeyInfo } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import {
  _isNumberOrLetter,
  _integer,
  _polishing,
  _isAllNumber,
} from "@/utils/verify";
import { _getCurrentDate, _convertCurrency } from "@/utils/utils";
import UploadImg from "@/components/commonCmpt/uploadImg";
import TXmap from "@/components/commonCmpt/TXmap";
import Step from "@/components/businessCmpt/step";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import basicMixin from "@/mixins/basic";
import { mapGetters } from "vuex";
export default {
  mixins: [basicMixin],
  data() {
    return {
      KPRemark: "", // 开票备注
      flag: {
        payStatus: 0, //0未付款 1已付款 2放弃付款中 3已放弃付款 4支付中
        step: 1, //表示当前所处第几步
        invoicing: false, //是否已经开过发票了
        payedDialig: false, //第一步已付款  这个变量仅用来控制dialog显示  实际付款与否是taskType控制的
        noPayedDialig: false, //第一步未付款  这个变量仅用来控制dialog显示  实际付款与否是taskType控制的
        showLedgerDialog: false, //是否已经分账成功
        ledgerText: "货主已付款，银行分账处理中，请耐心等待", //分账提示文字
        uploadInvoiceDialog: false, //第三步的ocr上传识别dialog
        activeTab: "0", //第三步当前激活的tab
        editInvoiceDialog: false, //第三步的补录发票信息dialog
        uploadingInvoice: false, //上传发票中
        uploadNormalInvoice: false, //上传正常发票中
        uploadSpecialInvoice: false, //上传特殊发票中
        detailDialog: false, //第四步的查看详情dialog
        sendBossDialog: false, //发送至货主
        showMap: false, //显示地图
        showVehicleInfo: false, //显示车辆信息
        compositioning: false, //组合发票中
        sendInvoice: false, //是否已经发送发票给货主了
        showDriverInfo: false, //显示司机信息
        waybillType: "", //业务类型 货物承运 委托代开
        taskSceneTypeID: null, //场景类型id
        showCompositionBtn: true, //显示自动组合按钮
        packaging: false, //手动组合中
        downBillsDetailsIng: false, //下载表格中
        showGiveUpInvoice: false, //同意货主放弃付款dialog
        downloadTaxationExcelIng: false, //下载税务表格中
        printDataStoreIng: false, //暂存中
        sendBossIng: false, //发送至货主中
        dialogLoading: false, //dialog弹窗是否是loading状态
        uploadErrorDialog: false, //发送至货主错误信息
        chooseInvoiceDialig: false, //发票选择弹窗
        previewInvioceDialog: false, // 预览发票
        questionPreviewDialog: false, // 发票样本预览
        isFresh: false, // 刷新按钮
        choseInvoiceBtn: false, //自动组合确定按钮
        invoiceChoosePopover: true, // 选择发票信息弹窗
        invoiceProgressDialog: false, // 开票中进度弹窗
        invoiceProgress: 0, // 开票进度
        updateRemarkDialog: false, // 描述弹窗
        sendOwnerOperate: true, //发送至货主弹窗控制
      },
      ukeyOptions: [], // ukey列表
      keyId: "", // 选择的ukey
      invoiceTypeChosed: {},
      invoiceStatusList: [
        {
          Name: "全部",
          Code: 0,
        },
        {
          Name: "已开票",
          Code: 1,
        },
        {
          Name: "未开票",
          Code: 2,
        },
      ], //发票状态
      invoiceStoreList: [], // 发票总余量
      searchForm: {
        type: 0, // 发票状态
        FeeType: "", // 开票类型
      },
      vehicleInfo: {}, //车辆信息
      //第一步相关的字段
      invoiceTaskID: "",
      //第二步相关的字段
      driverInfo: {}, //司机信息
      facaValue: "", //发票面额
      facaValueList: [{ label: 0, value: 0 }], //发票面额列表
      currentAllFacaValue: {
        Amount: 0, //运费
        Taxes: 0, //服务费/税费
        TotalAmount: 0, //运单总金额
        Count: 0, //发票数量
      }, //当前组合的发票信息
      tableData1Total: [], //表格列表
      currentTable1dataSelectedList: [], //表格中选中的值
      currentTable1dataSelectedCount: 0, //表格中选中的个数
      packageList: [], //组合出来的打票包
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      //第三步相关的字段
      pagination2: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination3: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination4: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination5: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      paginationElectron: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      invoicePagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData2Total: [], //表格总量
      currentIndex: null, //当前正在编辑的表格索引
      currentFile: "", //当前正在操作的文件
      wayBillTotal: {}, //顶部卡片总计
      consign: {}, //顶部卡片总计货主信息
      //第四步相关的字段
      tableData3: [], //查看详情的表格
      sendBossForm: {
        expressName: "", //快递名称
        expressNumber: "", //快递单号
      },
      printTaskID: "", //打票单号
      mapList: [], //轨迹列表
      uploadErrorList: [], //发送至货主错误信息
      combinType: 2, // 发票选择
      chosedElectronInvoice: [], //选中的电子发票
      invoiceAction: 1, //电子发票选择的操作
      invoiceName: "", // 发票项目
      multipleSelection: [], // 选中的数据
      previewInvoiceInfo: {
        buyerInfo: {},
        sellerInfo: {},
      }, // 预览发票明细
      UKEYList: [
        {
          ukey: "497012249846",
          num: 5000,
        },
        {
          ukey: "497012249846",
          num: 5000,
        },
        {
          ukey: "497012249846",
          num: 5000,
        },
        {
          ukey: "497012249846",
          num: 5000,
        },
      ],
      checkUKEYList: [],
      isAllChecked: false,
      currentItem: {},
    };
  },
  methods: {
    isImage(filename) {
      return /\.(jpg|jpeg|png|gif)$/i.test(filename); // 返回结果为true或false，根据文件名是否符合图像格式后缀进行判断
    },
    DownLoadInvoiceExcel() {
      window.location.href = 'https://res.gts56.com/fzkj/template/waybill/%E5%BC%80%E7%A5%A8%E8%A1%A8%E6%A0%BC%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 上传表格
    handleUploadData(e) {
      const loading = this.$loading({
        lock: true,
        text: "上传表格中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      ImportInvoiceTable(formData)
        .then((res) => {
          if (res.data.ErrorUrl) {
            window.location.href = res.data.ErrorUrl
          }
          this.$message.success("表格上传完成！")
        })
        .finally(() => {
          this.$refs.uploadData.value = null;
          loading.close();
        });
    },
    // 改变发票状态查询
    changeType() {
      this.pagination2.page = 1;
      this.statisWaybill();
    },
    // 返回待办
    goBack() {
      this.$router.push({
        path: "/commission/pendingManage/pendingDetail",
        query: {
          currentStep: this.$route.query.currentStep,
          enterpriseFullName: this.$route.query.enterpriseFullName,
        },
      });
    },
    //打开修改描述弹窗
    openUpdateReamrk(item) {
      this.currentItem = JSON.parse(JSON.stringify(item));
      this.flag.updateRemarkDialog = true;
    },
    //修改备注
    updateRemark() {
      let params = {
        printTaskID: this.currentItem.PrintTaskID,
        invoiceDesc: this.currentItem.InvoiceDesc,
      };
      UpdateInvoiceDesc(params)
        .then((res) => {
          this.statisWaybill("fresh");
        })
        .finally(() => {
          this.flag.updateRemarkDialog = false;
        });
    },
    // 一键开票预览
    autoInvoice(item) {
      if (!this.invoiceName) {
        this.$message.warning(" 请输入'发票项目'的内容并保存！");
        return;
      }
      if (!this.multipleSelection.length) {
        this.$message.warning("请选择需要开的发票");
        return;
      }
      let printTaskID = item.PrintTaskID
        ? item.PrintTaskID
        : this.tableData2Total[0].PrintTaskID;
      PreviewInvoice({
        printTaskID,
      }).then((res) => {
        this.previewInvoiceInfo = res.data || {
          buyerInfo: {},
          sellerInfo: {},
        };
        this.previewInvoiceInfo.NonTaxAmountConvert = _convertCurrency(
          Number(this.previewInvoiceInfo.NonTaxAmount) +
          Number(this.previewInvoiceInfo.TaxFee).toFixed(2)
        );
      });
      this.flag.previewInvioceDialog = true;
    },
    // 提交一键开票
    submitAutoInvoice() {
      let isAllFinish = [];
      this.tableData2Total.map((item) => {
        isAllFinish.push(
          item.AutoStatus != 2 && item.AutoStatus != 20 && item.AutoStatus != 21
        );
      });
      if (this.invoiceTypeChosed.RemainNum == 0) {
        this.$message.warning("发票张数为0，请上传发票之后再保存");
        return;
      }
      if (!isAllFinish.includes(true)) {
        this.$message.warning("未查询到需开票的结算单");
        return;
      }
      this.flag.invoiceProgressDialog = true;
      this.flag.invoiceProgress = 0;
      let progress = 0; //已经开了多少条
      //开始
      let params = {
        action: 0,
        listInvoice: [],
        invoiceLine: this.invoiceTypeChosed.InvoiceLine,
        uKey: this.invoiceTypeChosed.MachineCode,
      };
      this.flag.previewInvioceDialog = false;
      this.multipleSelection.forEach((item) => {
        params.listInvoice = [];
        params.listInvoice.push({
          printTaskID: item.PrintTaskID,
          invoiceSerialNum: item.InvoiceSerialNum,
        });
        BatchInvoiceAction(params)
          .then((res) => { })
          .catch((res) => { })
          .finally(() => {
            progress = progress + 1;
            this.flag.invoiceProgress = parseInt(
              (progress / this.multipleSelection.length) * 100
            );
            if (this.flag.invoiceProgress == 100) {
              //开票完毕后需要刷新列表获取新数据
              this.statisWaybill();
              this.printDataStore("autoInvoice");
              this.$message("开票完成");
              setTimeout(() => {
                this.flag.invoiceProgressDialog = false;
              }, 2000);
            }
          });
      });
    },
    //每页条数改变
    sizeChange(e) {
      this.pagination2.pagesize = e;
    },
    // 获取发票余量
    getInvoiceStore(keyID) {
      GetInvoiceStore({
        keyID,
        bReqHX: false,
      }).then((res) => {
        this.invoiceStoreList = [];
        if (res.data.invoiceStoreList && res.data.invoiceStoreList.length) {
          this.invoiceStoreList = res.data.invoiceStoreList;
          if (!this.invoiceTypeChosed.InvoiceLine) {
            this.invoiceTypeChosed = res.data.invoiceStoreList[0];
          } else {
            this.invoiceStoreList.map((it) => {
              if (it.InvoiceLine == this.invoiceTypeChosed.InvoiceLine) {
                this.invoiceTypeChosed = it;
              }
            });
          }
        }
      });
    },
    // 选中电子发票
    selectChangeElectronInvoice(e) {
      this.chosedElectronInvoice = e.records;
    },
    //电子发票查询、冲红、重开
    batchInvoiceAction(type, row) {
      this.invoiceAction = type;
      let params = {
        action: type,
        listInvoice: [],
        invoiceLine: this.invoiceTypeChosed.InvoiceLine,
        uKey: this.invoiceTypeChosed.MachineCode,
      };
      if (row) {
        params.listInvoice = [
          {
            printTaskID: row.PrintTaskID,
            invoiceSerialNum: row.InvoiceSerialNum,
          },
        ];
      } else {
        this.chosedElectronInvoice.map((item) => {
          params.listInvoice.push({
            printTaskID: item.PrintTaskID,
            invoiceSerialNum: item.InvoiceSerialNum,
          });
        });
      }
      let text = "";
      if (type == 1) {
        text = "开票中...";
      } else if (type == 3) {
        text = "下载中...";
      } else if (type == 4) {
        text = "查询中...";
      }
      if (type != 3) {
        const loading = this.$loading({
          lock: true,
          text: text,
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        BatchInvoiceAction(params)
          .then((res) => {
            this.getInvoiceDetails(
              this.tableData2Total[this.currentIndex].PrintTaskID
            );
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      } else {
        window.open(row, "_blank");
      }
    },
    // 选中表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取发票名称
    getInvoiceName() {
      GetInvoiceName().then((res) => {
        this.invoiceName = res.data.transInvoiceName;
      });
    },
    //更新发票名称
    updateInvoiceName() {
      if (!this.invoiceName) {
        this.$message.warning("请输入发票项目名！");
        return;
      }
      UpdateInvoiceName({
        transInvoiceName: this.invoiceName,
        tecInvoiceName: "",
      }).then((res) => {
        this.getInvoiceName();
      });
    },
    cancleChooseInvoice() {
      this.flag.chooseInvoiceDialig = false;
      this.flag.compositioning = false;
    },
    //耐心等待
    waitPatiently() {
      this.flag.noPayedDialig = false;
      this.goBack();
    },
    //服务商放弃开票
    providerGiveUpInvoice() {
      let params = {
        taskId: this.invoiceTaskID,
      };
      providerGiveUpInvoice(params).then((res) => {
        this.goBack();
      });
    },
    //第一步的方法
    //查询货主是否已付款
    ownerPays() {
      if (!this.invoiceTaskID) {
        this.$router.push("/commission/pendingManage/index");
        return;
      }
      let params = {
        invoiceTaskID: this.invoiceTaskID,
      };
      ownerPays(params)
        .then((res) => {
          this.KPRemark = res.KPRemark;
          //res.Pays   0未付款 1已付款 2放弃付款中 3已放弃付款 4支付中
          //res.billPayStatus   0未到账  1已到账  2转账失败  这个字段权重比Pays高
          if (res.Pays == 0) {
            //未付款
            this.flag.noPayedDialig = true;
          } else if (res.Pays == 1) {
            //已付款
            //查看是否是线上付款
            if (res.PayMethod == 1 || res.PayMethod == 4) {
              //线下付款
              this.flag.payStatus = 1;
              this.flag.payedDialig = true;
            } else if (res.PayMethod == 2 || res.PayMethod == 3) {
              //线上付款并且已付款再查询是否分账完成
              if (res.billPayStatus == 0) {
                //分账未到账
                this.flag.showLedgerDialog = true;
                this.flag.ledgerText = "货主已付款，银行分账处理中，请耐心等待";
              } else if (res.billPayStatus == 1) {
                //分账已到账
                this.flag.payStatus = 1;
                this.flag.payedDialig = true;
              }
            }
          } else if (res.Pays == 2) {
            //放弃付款中
          } else if (res.Pays == 3) {
            //已放弃付款
          } else if (res.Pays == 4) {
            //支付中
            this.flag.payStatus = 4;
            this.flag.noPayedDialig = true;
          }
        })
        .finally(() => {
          //等查完这个接口再查服务商是否开票  这么做是控制弹窗的优先级
          //查询服务商是否开过票
          this.verifyPrinTaskID();
        });
    },
    //查询服务商是否已经开过票
    verifyPrinTaskID() {
      let params = {
        DpId: this.invoiceTaskID,
      };
      verifyPrinTaskID(params).then((res) => {
        //第一次走流程
        if (res.VerifyInvoice.ComInvoiceStatus == 0) {
          if (res.VerifyInvoice.TaskStatus == 7) {
            //说明是暂存的运单
            this.flag.step = 3;
            this.statisWaybill();
            //获取发票名
            this.getInvoiceName();
          }
          //之前组合过发票
        } else if (res.VerifyInvoice.ComInvoiceStatus == 1) {
          if (res.VerifyInvoice.TaskStatus == 7) {
            //说明是暂存的运单
            this.flag.step = 3;
            this.statisWaybill();
            //获取发票名
            this.getInvoiceName();
          }
          //已经开票完成了
        } else if (res.VerifyInvoice.ComInvoiceStatus == 2) {
          this.flag.invoicing = true;
          this.$message.error("这批运单已开过发票，请勿重复操作");
        }
      });
    },

    //第二步的方法
    //这个方法是为了给表头加上这个类名  更改样式去掉全选功能
    cellClass(row) {
      if (row.columnIndex === 0) {
        return "DisableSelection";
      }
    },
    checkedAll() {
      if (this.isAllChecked) {
        this.currentTable1dataSelectedList = [];
        let num = Math.ceil(this.pagination1.total / this.pagination1.pagesize);
        for (let i = 0; i <= num; i++) {
          this.currentTable1dataSelectedList[i] = this.tableData1Total.slice(
            this.pagination1.pagesize * i,
            this.pagination1.pagesize * (i + 1)
          );
        }
        this.tableData1Total.forEach((el) => {
          this.$refs.currentTable1dataRef.toggleRowSelection(el, true);
        });
      } else {
        this.currentTable1dataSelectedList = [];
        this.tableData1Total.forEach((el) => {
          this.$refs.currentTable1dataRef.toggleRowSelection(el, false);
        });
      }
      let arr = [];
      //当前组合包发票总面额
      this.currentTable1dataSelectedList.forEach((item) => {
        arr = arr.concat(item);
      });
      //把运单传给后台让后台计算金额
      combinaStatis({ BillsId: arr, InvoiceDenomination: this.facaValue }).then(
        (res) => {
          this.currentAllFacaValue = res.data;
          this.currentTable1dataSelectedCount = res.data.Count;
        }
      );
    },
    //处理表格选中变化
    currentTable1dataSelectedChange(e, row) {
      this.currentTable1dataSelectedList[this.pagination1.page - 1] = [];
      let arrChange = this.tableData1Total.slice(
        this.pagination1.pagesize * (this.pagination1.page - 1),
        this.pagination1.pagesize * this.pagination1.page
      );
      e.map((item) => {
        arrChange.map((it) => {
          if (item.WaybillNumber === it.WaybillNumber) {
            this.currentTable1dataSelectedList[this.pagination1.page - 1].push(
              item
            );
          }
        });
      });
      let arr = [];
      //当前组合包发票总面额
      // let count = 0
      this.currentTable1dataSelectedList.forEach((item) => {
        arr = arr.concat(item);
      });
      //把运单传给后台让后台计算金额
      combinaStatis({ BillsId: arr, InvoiceDenomination: this.facaValue }).then(
        (res) => {
          this.currentAllFacaValue = res.data;
          this.currentTable1dataSelectedCount = res.data.Count;
        }
      );
    },
    //查看司机信息
    openDerverInfo(UserID) {
      this.flag.dialogLoading = true;
      let data = {};
      getDriverDetails({ UserID: UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //获取发票面额
    getDenomination() {
      getDenomination().then((res) => {
        this.facaValue = res.denomin.Denomination;
        this.facaValueList = [{ label: this.facaValue, value: this.facaValue }];
      });
    },
    //获取运单号
    billsInfoDeatils() {
      const loading = this.$loading({
        lock: true,
        text: "数据请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        InvoiceTaskID: this.invoiceTaskID,
        PageSize: 100000,
        PageIndex: this.pagination1.page, //这么写是为了分页在前端做
      };
      billsInfoDeatils({ json: JSON.stringify(params) })
        .then((res) => {
          loading.close();
          this.pagination1.total = Number(res.totalRowCount);
          this.tableData1Total = res.invoices.map((item, index) => {
            item.facaValue = 0;
            item.index = index + 1;
            return item;
          });
          //处理成二维数组
          let arrLength = parseInt(
            Number(res.invoices.length) / this.pagination1.pagesize + 1
          );
          for (let i = 0; i < arrLength; i++) {
            this.currentTable1dataSelectedList[i] = [];
          }
          //确定业务类型和场景类型
          this.flag.waybillType = this.tableData1Total[0].TaskType;
          this.flag.taskSceneTypeID = this.tableData1Total[0].TaskSceneTypeID;
        })
        .catch(() => {
          loading.close();
        });
    },
    //分页回调
    handleCurrentChange1(e) {
      this.pagination1.page = e;
    },
    pagination1SizeChange(e) {
      this.pagination1.pagesize = e;
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //计入1个发票包
    packaging() {
      let isNull = true;
      this.currentTable1dataSelectedList.forEach((item) => {
        if (item.length != 0) {
          isNull = false;
        }
      });
      if (isNull) {
        this.$message.warning("请勾选要组合的运单再进行打包");
        return;
      }
      this.flag.packaging = true;
      let arr = [];
      this.currentTable1dataSelectedList.forEach((item) => {
        item.forEach((el1) => {
          this.tableData1Total.forEach((el2, inx2) => {
            this.flag.showCompositionBtn = false;
            if (el1.WaybillNumber === el2.WaybillNumber) {
              let temp = this.tableData1Total.splice(inx2, 1)[0];
              temp.facaValue = this.facaValue;
              arr.push(temp);
            }
          });
        });
      });
      this.pagination1.total = Number(this.tableData1Total.length);
      //清除之前的选中数据，重新处理成新的二维数组
      let arrLength = parseInt(
        Number(this.tableData1Total.length) / this.pagination1.pagesize + 1
      );
      this.currentTable1dataSelectedList = [];
      for (let i = 0; i < arrLength; i++) {
        this.currentTable1dataSelectedList[i] = [];
      }
      //当前组合的发票面额清零
      this.currentAllFacaValue.Amount = 0;
      this.currentAllFacaValue.Count = 0;
      this.currentAllFacaValue.Taxes = 0;
      this.currentAllFacaValue.TotalAmount = 0;
      this.packageList.push(arr);
      this.flag.packaging = false;
      this.pagination1.page = 1;
    },
    //手动组合
    manualComposition() {
      if (this.tableData1Total.length != 0) {
        this.$message.warning("尚有运单未被组合");
        return;
      }
      this.flag.compositioning = true;
      let params = {
        InvoiceTaskID: this.getInvoiceTaskID,
        manuaList: this.packageList,
      };
      const loading = this.$loading({
        lock: true,
        text: "数据请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      manualCombina({ Json: JSON.stringify(params) })
        .then((res) => {
          //清除组合的信息
          this.currentAllFacaValue.Amount = 0;
          this.currentAllFacaValue.Count = 0;
          this.currentAllFacaValue.Taxes = 0;
          this.currentAllFacaValue.TotalAmount = 0;
          //获取组合后的结算单
          this.statisWaybill();
          //获取发票名
          this.getInvoiceName();
          this.flag.compositioning = false;
          this.flag.step = 3;
          loading.close();
        })
        .catch(() => {
          this.flag.compositioning = false;
          loading.close();
        });
    },
    //自动组合
    composition() {
      this.flag.chooseInvoiceDialig = true;
      if (!this.facaValue) {
        this.$message.error("请选择发票面额");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "数据请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.flag.compositioning = true;
      this.flag.choseInvoiceBtn = true;
      let arr = [];
      if (this.flag.waybillType == "01") {
        this.tableData1Total.forEach((item) => {
          arr.push({
            WaybillNumber: item.WaybillNumber,
            InvoiceTaskID: this.invoiceTaskID,
            Recy_payment_idcard: item.Recy_payment_idcard,
            Recy_payment_amount: item.Recy_payment_amount,
            amount_count: item.amount_count,
          });
        });
        let params = {
          resp: arr,
          Denomination: this.facaValue,
          combinType: this.combinType,
        };
        combinInvoice(params)
          .then((res) => {
            debugger;
            //清除组合的信息
            this.currentAllFacaValue.Amount = 0;
            this.currentAllFacaValue.Count = 0;
            this.currentAllFacaValue.Taxes = 0;
            this.currentAllFacaValue.TotalAmount = 0;
            //获取组合后的结算单
            this.statisWaybill();
            //获取发票名
            this.getInvoiceName();
            this.flag.compositioning = false;
            this.flag.step = 3;
            this.flag.chooseInvoiceDialig = false;
            this.flag.choseInvoiceBtn = false;
            this.combinType = 2;
          })
          .catch(() => {
            this.flag.compositioning = false;
            this.flag.chooseInvoiceDialig = false;
            this.flag.choseInvoiceBtn = false;
            loading.close();
          });
        //发送请求
      } else {
        this.tableData1Total.forEach((item) => {
          arr.push({
            WaybillNumber: item.WaybillNumber,
            InvoiceTaskID: this.invoiceTaskID,
            Recy_payment_idcard: item.Recy_payment_idcard,
            Recy_payment_amount: item.Recy_payment_amount,
            amount_count: item.Entrusted_Collection_Amount,
          });
        });
        let params = {
          resp: arr,
          Denomination: this.facaValue,
          combinType: this.combinType,
        };
        commissionCombinInvoice(params)
          .then((res) => {
            //清除组合的信息
            this.currentAllFacaValue.Amount = 0;
            this.currentAllFacaValue.Count = 0;
            this.currentAllFacaValue.Taxes = 0;
            this.currentAllFacaValue.TotalAmount = 0;
            //获取组合后的结算单
            this.statisWaybill();
            //获取发票名
            this.getInvoiceName();
            this.flag.compositioning = false;
            this.flag.step = 3;
            this.flag.chooseInvoiceDialig = false;
            this.flag.choseInvoiceBtn = false;
            this.combinType = 2;
          })
          .catch(() => {
            this.flag.chooseInvoiceDialig = false;
            this.flag.compositioning = false;
            this.flag.choseInvoiceBtn = false;
            loading.close();
          });
      }
    },
    //下载税务表格
    downBillsDetails() {
      this.flag.downBillsDetailsIng = true;
      let params = {
        invoiceTaskId: this.getInvoiceTaskID,
      };
      RefundWaybill(params)
        .then((res) => {
          window.location.href = res.data;
        })
        .finally(() => {
          this.flag.downBillsDetailsIng = false;
        });
    },
    //暂存
    printDataStore(type) {
      const loading = this.$loading({
        lock: true,
        text: "暂存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.printDataStoreIng = true;
      let invoices = []; //正常发票
      let specials = []; //特殊发票
      let checks = []; //凭证
      this.tableData2Total.forEach((item) => {
        // item.invoiceImgList.forEach(el => {
        //   invoices.push({
        //     InvoiceImgUrl: el.picUrl, //发票图片地址
        //     InvoiceNum: el.invoiceNum, //发票号码
        //     PrintInvoiceTaskID: item.PrintTaskID, //打票单号
        //     InvoiceTime: el.CreateTime,
        //     InvoiceCode: el.invoiceCode,
        //     AmountIssued: el.money, //开具金额
        //     TaxIncreaseAlloy: el.total, //加税合计
        //     CheckCode: el.verifyCode, //校验码
        //     InvcieContent: JSON.stringify(el.invoiceContent), //发票内容
        //   })
        // })
        item.specialInvoiceList.forEach((el) => {
          if (el.IsOcr) {
            invoices.push({
              InvoiceImgUrl: el.picUrl, //发票图片地址
              InvoiceNum: el.invoiceNum, //发票号码
              PrintInvoiceTaskID: item.PrintTaskID, //打票单号
              InvoiceCode: el.invoiceCode,
              InvoiceTime: el.CreateTime,
              AmountIssued: el.money, //开具金额
              TaxIncreaseAlloy: el.total, //加税合计
              CheckCode: el.verifyCode, //校验码
              InvcieContent: JSON.stringify(el.invoiceContent), //发票内容
            });
          } else {
            specials.push({
              InvoiceImgUrl: el.picUrl, //发票图片地址
              InvoiceNum: el.invoiceNum, //发票号码
              PrintInvoiceTaskID: item.PrintTaskID, //打票单号
              InvoiceCode: el.invoiceCode,
              InvoiceTime: el.CreateTime,
              AmountIssued: el.money || 0, //开具金额
              TaxIncreaseAlloy: el.total || 0, //加税合计
              CheckCode: el.verifyCode, //校验码
              InvcieContent: JSON.stringify(el.invoiceContent), //发票内容
            });
          }
        });
        item.voucherList.forEach((el) => {
          checks.push({
            ImageUrl: el.picUrl,
            PrintTaskId: item.PrintTaskID, //打票单号
            Name: el.name,
          });
        });
      });
      printDataStore({
        Json: JSON.stringify({
          InvoiceTaskId: this.getInvoiceTaskID,
          expressName: "",
          trackingNum: "",
          Status: "",
          Invoices: invoices,
          Specials: specials,
          Checks: checks,
        }),
      })
        .then((res) => {
          this.statisWaybill();
          this.flag.activeTab = "0";
          if (type == "autoInvoice") {
            return;
          } else if (type == "paperInvoice") {
            return;
          } else {
            this.goBack();
          }
        })
        .catch((res) => {
          this.uploadErrorList = res.data.invoices;
          this.flag.uploadErrorDialog = true;
        })
        .finally(() => {
          this.printDataStoreIng = false;
          loading.close();
        });
    },

    //第三步的方法
    //服务商统计运单信息
    statisWaybill(type) {
      let params = {
        pageIndex: 1,
        PageSize: this.pagination2.pagesize,
        // PageSize: 100000, //一次性把数据全部拿回来然后前端自己分页
        InvoiceTaskID: this.invoiceTaskID,
        CarNumber: "",
        WaybillID: "",
        InvoiceNumber: "",
        VehicleCarNumber: "",
        PrincipalCompany: "",
        DriverPhone: "",
        StartDatetime: "",
        EndDatetime: "",
        TaskDatetime: "",
        IsKP: this.searchForm.type,
        FeeType: this.searchForm.FeeType,
      };
      const loading = this.$loading({
        lock: true,
        text: "数据请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      statisWaybill({ Json: JSON.stringify(params) })
        .then((res) => {
          this.flag.taskSceneTypeID =
            res.invoices && res.invoices.length
              ? res.invoices[0].TaskSceneTypeID
              : "";
          this.flag.waybillType =
            res.invoices && res.invoices.length ? res.invoices[0].TaskType : "";
          this.wayBillTotal = res.wayBill;
          this.consign = res.Consign;
          this.pagination2.total = Number(res.totalRowCount);
          this.tableData2Total = res.invoices.map((item, index) => {
            item.index = index + 1;
            item.electronInvoiceList = []; //电子发票列表
            item.invoiceImgList = []; //正常发票列表
            item.specialInvoiceList = []; //特殊发票列表
            item.voucherList = []; //凭证清单列表
            let invoiceNum = [];
            let invoiceDate = [];
            item.invoiceInfos.forEach((el, inx) => {
              if (el.Medium == 1) {
                item.electronInvoiceList.push({
                  index: inx + 1,
                  ...el,
                });
              }
              if (el.Medium == 0) {
                item.invoiceImgList.push({
                  index: inx + 1,
                  picUrl: el.InvoiceImgUrl,
                  invoiceNum: el.InvoiceNumber,
                  invoiceContent: "",
                  money: el.InvoiceAmount, //开具金额
                  total: el.InvoiceTotalAmount, //价税合计
                  verifyCode: el.CheckCode, //校验码
                  invoiceCode: el.InvoiceCode,
                  CreateTime: el.CreateTime,
                });
                item.specialInvoiceList.push({
                  index: inx + 1,
                  picUrl: el.InvoiceImgUrl,
                  invoiceNum: el.InvoiceNumber,
                  invoiceContent: "",
                  money: el.InvoiceAmount, //开具金额
                  total: el.InvoiceTotalAmount, //价税合计
                  verifyCode: el.CheckCode, //校验码
                  invoiceCode: el.InvoiceCode,
                  CreateTime: el.CreateTime,
                  IsOcr: el.IsOcr,
                });
              }
            });

            item.SpecailInvoice.forEach((el, inx) => {
              item.specialInvoiceList.push({
                index: inx + 1,
                invoiceCode: el.InvoiceCode,
                invoiceNum: el.InvoiceNumber,
                picUrl: el.InvoiceImgUrl,
                money: el.InvoiceAmount,
                total: el.InvoiceTotalAmount,
                CreateTime: el.CreateTime,
                verifyCode: el.CheckCode,
                IsOcr: el.IsOcr,
              });
            });

            item.checkList.forEach((el, inx) => {
              item.voucherList.push({
                index: inx + 1,
                picUrl: el.EvidenceImgUrl,
                name: el.Name,
              });
            });
            item.electronInvoiceList.forEach((el) => {
              invoiceNum.push(el.InvoiceNumber);
              invoiceDate.push(el.CreateTime);
            });
            item.specialInvoiceList.forEach((el) => {
              invoiceNum.push(el.invoiceNum);
              invoiceDate.push(el.CreateTime);
            });
            item._invoiceNum = invoiceNum.join(",");
            item.CreateTime = invoiceDate.join(",");

            return item;
          });
          if (this.wayBillTotal.bAuto) {
            this.getUKeyInfo();
          }
          if (type == "fresh") {
            this.$message("数据刷新成功，若还有数据未显示请稍后刷新！");
            this.flag.isFresh = true;
            setTimeout(() => {
              this.flag.isFresh = false;
            }, 30 * 1000);
          }
        })
        .catch(() => {
          loading.close();
        })
        .finally();
      {
        loading.close();
      }
    },
    // 获取金税盘信息
    async getUKeyInfo() {
      this.ukeyOptions = [];
      await GetUKeyInfo()
        .then((res) => {
          if (res.data.uKeyList.length) {
            res.data.uKeyList.map((it) => {
              this.ukeyOptions.push({
                value: it.KeyID,
                label: `UKEY：${it.KeyID}\u00A0\u00A0\u00A0\u00A0收款人：${it.Payee}\u00A0\u00A0\u00A0\u00A0复核：${it.Checker}\u00A0\u00A0\u00A0\u00A0开票人：${it.Drawer}`,
              });
            });
            if (!this.keyId) {
              this.keyId = res.data.uKeyList[0].KeyID;
              this.getInvoiceStore(res.data.uKeyList[0].KeyID);
            }
          }
        })
        .catch(() => { });
    },
    // 刷新列表
    freshList() {
      const loading = this.$loading({
        lock: true,
        text: "数据请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let freshList = [];
      this.tableData2Total.map((item) => {
        if (item.AutoStatus == 20 || item.AutoStatus == 21) {
          if (!item.InvoiceNumber || !item.CreateTime) {
            freshList.push({
              printTaskID: item.PrintTaskID,
              invoiceSerialNum: item.InvoiceSerialNum,
            });
          }
        }
      });
      if (freshList.length) {
        BatchInvoiceAction({
          action: 4,
          listInvoice: freshList,
          invoiceLine: this.invoiceTypeChosed.InvoiceLine,
          uKey: this.invoiceTypeChosed.MachineCode,
        })
          .then((res) => {
            this.statisWaybill("fresh");
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      } else {
        this.statisWaybill("fresh");
      }
    },
    //分页回调
    handleCurrentChange2(e) {
      this.pagination2.page = e;
    },
    //分页回调
    handleCurrentChange3(e) {
      this.pagination3.page = e;
    },
    //分页回调
    handleCurrentChange4(e) {
      this.pagination4.page = e;
    },
    //分页回调
    handleCurrentChange5(e) {
      this.pagination5.page = e;
    },
    // 电子发票分页
    handleCurrentChangeElectron(e) {
      this.paginationElectron.page = e;
    },
    //显示上传弹窗
    _uploadInvoice(item, index) {
      this.currentIndex = index;
      this.pagination3.total =
        this.tableData2Total[this.currentIndex].invoiceImgList.length;
      this.pagination4.total =
        this.tableData2Total[this.currentIndex].specialInvoiceList.length;
      this.pagination5.total =
        this.tableData2Total[this.currentIndex].voucherList.length;
      this.paginationElectron.total =
        this.tableData2Total[this.currentIndex].electronInvoiceList.length;
      this.flag.uploadInvoiceDialog = true;
    },
    // 获取电子发票
    async getInvoiceDetails(printTaskID) {
      await GetInvoiceDetails({ printTaskID: printTaskID }).then((res) => {
        if (res.data.InvoiceList) {
          this.tableData2Total[this.currentIndex].electronInvoiceList =
            res.data.InvoiceList.map((item, index) => {
              item.index = index + 1;
              return item;
            }).filter((i) => {
              return i.Medium == 1;
            });
          this.paginationElectron.total =
            this.tableData2Total[this.currentIndex].electronInvoiceList.length;
        }
      });
    },
    //关闭上传弹窗
    handleCloseUploadInvoiceDialog() {
      let flag = false; //是否还有必填项每天
      this.tableData2Total[this.currentIndex].specialInvoiceList.forEach(
        (item) => {
          if (!item.invoiceCode || !item.invoiceNum || !item.CreateTime) {
            flag = true;
          }
        }
      );
      if (flag) {
        this.$message.warning("发票代码、发票号码、开票日期都为必填项");
      } else {
        try {
          this.tableData2Total[this.currentIndex].specialInvoiceList.forEach(
            (item) => {
              this.tableData2Total[
                this.currentIndex
              ].specialInvoiceList.forEach((el) => {
                if (
                  item.invoiceCode === el.invoiceCode &&
                  item.invoiceNum === el.invoiceNum &&
                  item.index != el.index
                ) {
                  this.$message.error("发票中有重复发票");
                  throw new Error();
                }
              });
              if (!_isNumberOrLetter(item.invoiceCode)) {
                this.$message.error(
                  `发票-序号${item.index}的发票代码只能为数字和字母`
                );
                throw new Error();
              }
              if (item.invoiceCode.length >= 33) {
                this.$message.error(
                  `发票-序号${item.index}的发票代码超过33位限制`
                );
                throw new Error();
              }
              if (!_isNumberOrLetter(item.invoiceNum)) {
                this.$message.error(
                  `发票-序号${item.index}的发票号码只能为数字和字母`
                );
                throw new Error();
              }
              if (item.invoiceNum.length >= 33) {
                this.$message.error(
                  `发票-序号${item.index}的发票号码超过33位限制`
                );
                throw new Error();
              }
              // if (!_integer(item.money)) {
              //   this.$message.error(`发票-序号${item.index}的开具金额只能为数字，且仅保留两位小数`)
              //   throw new Error()
              // }
              // if (Number(item.money) > 10000000) {
              //   this.$message.error(`特殊发票-序号${item.index}的开具金额不能大于一千万`)
              //   throw new Error()
              // }
              // if (item.total) {
              //   if (!_integer(item.total)) {
              //     this.$message.error(`特殊发票-序号${item.index}的价税合计只能为数字，且仅保留两位小数`)
              //     throw new Error()
              //   }
              //   if (Number(item.total) > 20000000) {
              //     this.$message.error(`特殊发票-序号${item.index}的价税合计不能大于二千万`)
              //     throw new Error()
              //   }
              // }
              // if (item.verifyCode) {
              //   if (!_isAllNumber(item.verifyCode)) {
              //     this.$message.error(`特殊发票-序号${item.index}的校验码只能为纯数字`)
              //     throw new Error()
              //   }
              // }
            }
          );
          //处理金额为后面有两位小数点的格式
          // this.tableData2Total[this.currentIndex].specialInvoiceList.forEach(item => {
          //   item.money = item.money ? _polishing(item.money) : null
          //   item.total = item.total ? _polishing(item.total) : null
          // })
          let evidenceList = []
          let invoiceList = []
          this.tableData2Total[this.currentIndex].voucherList.map(item => {
            evidenceList.push(item.picUrl)
          })
          this.tableData2Total[this.currentIndex].specialInvoiceList.map(item => {
            invoiceList.push({
              invoiceImgUrl: item.picUrl,
              invoiceNumber: item.invoiceNum,
              invoiceCode: item.invoiceCode,
              createTime: item.CreateTime,
              isOcr: item.IsOcr,
            })
          })
          EidtInvoice({
            printTaskID: this.tableData2Total[this.currentIndex].PrintTaskID,
            invoiceList,
            evidenceList
          }).then(res => {
            this.flag.uploadInvoiceDialog = false;
            this.printDataStore("paperInvoice");
          })
        } catch (error) {
          console.log(error);
        }
      }
    },
    //上传正常发票
    handleInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files;
      if (fileList.length > 10) {
        this.$message.error("单次上传不可超过十张发票");
        this.$refs.invoice.value = null;
        return;
      }
      this.flag.uploadingInvoice = true;
      this.flag.uploadNormalInvoice = true;
      let count = 0; //已经上传完毕了多少条
      let fileListLength = fileList.length;
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData();
        formData.append("file", fileList[i]);
        uploadInvoices(formData)
          .then((res) => {
            if (
              !res.photoInfo[0].OcrInfo.data.发票号码 ||
              !res.photoInfo[0].OcrInfo.data.发票代码
            ) {
              this.$message.warning(
                "该发票识别不出发票号码等信息，请上传到特殊发票中再手动输入信息"
              );
              return;
            }
            this.tableData2Total.forEach((item) => {
              if (
                item.PrintTaskID ==
                this.tableData2Total[this.currentIndex].PrintTaskID
              ) {
                let hasSame = false;
                item.invoiceImgList.forEach((el) => {
                  if (
                    el.invoiceNum === res.photoInfo[0].OcrInfo.data.发票号码 &&
                    el.invoiceCode === res.photoInfo[0].OcrInfo.data.发票代码
                  ) {
                    hasSame = true;
                  }
                });
                //如果有相同的
                if (hasSame) {
                  this.$message.warning("请勿上传重复的发票");
                } else {
                  //没有相同的
                  //处理实例存储的发票号
                  //处理dialog组件的发票号
                  item.invoiceImgList.push({
                    index: item.invoiceImgList.length + 1,
                    picUrl: res.photoInfo[0].PhotoUrl,
                    invoiceNum: res.photoInfo[0].OcrInfo.data.发票号码,
                    invoiceContent: res.photoInfo[0].OcrInfo.data,
                    money: res.photoInfo[0].OcrInfo.data.不含税金额, //开具金额
                    total: res.photoInfo[0].OcrInfo.data.发票金额, //价税合计
                    verifyCode: res.photoInfo[0].OcrInfo.data.校验码, //校验码
                    invoiceCode: res.photoInfo[0].OcrInfo.data.发票代码,
                    CreateTime: res.photoInfo[0].OcrInfo.data.开票日期
                      .replace(/年/g, "-")
                      .replace(/月/g, "-")
                      .replace(/日/g, ""),
                  });
                  this.pagination3.total = this.pagination3.total + 1;
                }
              }
            });
          })
          .finally(() => {
            this.$refs.invoice.value = null;
            count = count + 1;
            if (count === fileListLength) {
              this.flag.uploadingInvoice = false;
              this.flag.uploadNormalInvoice = false;
            }
          });
      }
    },
    //删除正常发票
    handleInvoiceDelete(index) {
      this.$confirm("确认删除该发票吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pagination3.total = this.pagination3.total - 1;
          this.tableData2Total[this.currentIndex].invoiceImgList.splice(
            index,
            1
          );
        })
        .catch();
    },
    NoSpecialInvoiceListonClick() {
      this.tableData2Total[this.currentIndex].specialInvoiceList.push({
        index:
          this.tableData2Total[this.currentIndex].specialInvoiceList.length + 1,
        InvoiceImgUrl: "",
        InvoiceNum: null,
        InvoiceTime: null,
        AmountIssued: null,
        InvcieContent: null,
        InvoiceCode: null,
        CheckCode: null,
        TaxIncreaseAlloy: null,
        IsOcr: false,
      });
      this.pagination4.total = this.pagination4.total + 1;
    },
    //上传清单
    handleVoucherUpload(e) {
      this.flag.uploadingInvoice = true;
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      addInvoiceChecklist(formData)
        .then((res) => {
          this.tableData2Total[this.currentIndex].voucherList.push({
            index:
              this.tableData2Total[this.currentIndex].voucherList.length + 1,
            picUrl: res.checkList.ImageUrl,
            name: res.checkList.Name,
          });
          this.pagination5.total = this.pagination5.total + 1;
        })
        .finally(() => {
          this.$refs.voucher.value = null;
          this.flag.uploadingInvoice = false;
        });
    },
    //删除一个凭证
    handleVoucherDelete(page, index) {
      this.$confirm("确认删除该凭证吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pagination5.total = this.pagination5.total - 1;
          this.tableData2Total[this.currentIndex].voucherList.splice(
            (page - 1) * this.pagination5.pagesize + index,
            1
          );
        })
        .catch();
    },
    //下载凭证
    downloadVoucher(url) {
      window.open(url);
    },
    //上传特殊发票信息
    handleSpecialInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files;
      if (fileList.length > 10) {
        this.$message.error("单次上传不可超过十张发票");
        this.$refs.specialInvoice.value = null;
        return;
      }
      let count = 0; //已经上传完毕了多少条
      let fileListLength = fileList.length;
      this.flag.uploadingInvoice = true;
      this.flag.uploadSpecialInvoice = true;
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData();
        formData.append("file", fileList[i]);
        DisInvoices(formData)
          .then((res) => {
            this.tableData2Total[this.currentIndex].specialInvoiceList.push({
              index:
                this.tableData2Total[this.currentIndex].specialInvoiceList
                  .length + 1,
              picUrl: res.data.InvoiceImgUrl,
              invoiceNum: res.data.InvoiceNum,
              invoiceCode: res.data.InvoiceCode, //发票代码
              money: res.data.AmountIssued, //开具金额
              total: res.data.TaxIncreaseAlloy, //价税合计
              verifyCode: res.data.CheckCode, //校验码
              CreateTime: res.data.InvoiceTime,
              IsOcr: res.data.IsOcr, // 是否Ocr识别
            });
            this.pagination4.total = this.pagination4.total + 1;
          })
          .finally(() => {
            this.$refs.specialInvoice.value = null;
            count = count + 1;
            if (count === fileListLength) {
              this.flag.uploadingInvoice = false;
              this.flag.uploadSpecialInvoice = false;
            }
          });
      }
    },
    //删除特殊发票
    deleteSpecialInvoiceDelete(index) {
      this.$confirm("确认删除该发票吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData2Total[this.currentIndex].specialInvoiceList.forEach(
            (item, inx) => {
              if (item.index == index) {
                this.pagination4.total = this.pagination4.total - 1;
                this.tableData2Total[
                  this.currentIndex
                ].specialInvoiceList.splice(inx, 1);
              }
            }
          );
          this.tableData2Total[this.currentIndex].specialInvoiceList.forEach(
            (item, index) => {
              item.index = index + 1;
            }
          );
        })
        .catch();
    },
    //重新组合
    goStep(step) {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      AutoCheckInvoice({
        invoiceTaskID: this.getInvoiceTaskID,
      })
        .then((res) => {
          if (step == 2) {
            //重新组合发票包需要清空之前组合的
            this.flag.showCompositionBtn = true;
            this.packageList = [];
          }
          this.flag.step = step;
        })
        .finally(() => {
          loading.close();
        });
    },
    //开票完成
    invoicing() {
      CompleteVerify({
        invoiceTaskID: this.invoiceTaskID
      }).then(res => {
        if (res.data) {
          this.flag.step = 4;
        } else {
          this.$confirm('您还未“上传运费发票”，是否继续？', '提示', {
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.flag.step = 4;
          }).catch(() => {

          });
          // this.$message.error("您还未“上传运费发票”，是否继续？");
          return;
        }
      })
    },
    //下载税务表格
    downloadTaxationExcel() {
      this.flag.downloadTaxationExcelIng = true;
      let params = {
        invoiceTaskId: this.invoiceTaskID,
      };

      RefundWaybill(params)
        .then((res) => {
          window.location.href = res.data;
        })
        .finally(() => {
          this.flag.downloadTaxationExcelIng = false;
        });
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },

    //第四步的方法
    //查看详情
    open_detail(item) {
      this.invoicePagination.page = 1;
      this._detail(item.PrintTaskID).then(() => {
        this.flag.detailDialog = true;
      });
    },

    async _detail(id) {
      const loading = this.$loading({
        lock: true,
        text: "请求中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.printTaskID = id;
      let params = {
        InvoiceTaskID: this.printTaskID,
        pageIndex: this.invoicePagination.page,
        pageSize: this.invoicePagination.pagesize,
      };
      //货物承运
      if (this.flag.waybillType == "01" && this.flag.taskSceneTypeID == "1") {
        await getInvoiceDetaleds({ Json: JSON.stringify(params) })
          .then((res) => {
            console.log("res1", res);
            this.tableData3 = res.invoices.map((item, index) => {
              item.index = index + 1;
              return item;
            });

            this.invoicePagination.total = +res.totalRowCount || 0;
          })
          .finally(() => {
            loading.close();
          });
        //土石方
      } else if (
        this.flag.waybillType == "01" &&
        this.flag.taskSceneTypeID == "6"
      ) {
        await earthWorkGetInvoiceDetals({ Json: JSON.stringify(params) })
          .then((res) => {
            console.log("res2", res);
            this.tableData3 = res.invoices.map((item, index) => {
              item.index = index + 1;
              return item;
            });

            this.invoicePagination.total = +res.totalRowCount || 0;
          })
          .finally(() => {
            loading.close();
          });
      }
      //交通运输
      else if (
        (this.flag.waybillType == "02" && this.flag.taskSceneTypeID == "3") ||
        this.$entrustSceneIds.includes(Number(this.flag.taskSceneTypeID))
      ) {
        await invoiceTaskDetails({ Json: JSON.stringify(params) })
          .then((res) => {
            console.log("res3", res);
            this.tableData3 = res.invoices.map((item, index) => {
              item.index = index + 1;
              return item;
            });

            this.invoicePagination.total = +res.totalRowCount || 0;
          })
          .finally(() => {
            loading.close();
          });
      }
    },

    // 发票详情分页
    handleInvoicePageChange(e) {
      this.invoicePagination.page = e;
      this.printTaskID && this._detail(this.printTaskID);
    },

    //下载表格运单
    billDown() {
      let params = {
        invoiceTaskId: this.getInvoiceTaskID,
      };
      RefundWaybill(params).then((res) => {
        window.location.href = res.data;
      });
    },
    //确认发送至货主 -> 寄送纸质票
    sendBoss(status) {
      //纸质发票
      if (status == 1) {
        if (
          !this.sendBossForm.expressName ||
          !this.sendBossForm.expressNumber
        ) {
          this.$message.warning("请填写快递公司和快递单号");
          return;
        }
      }
      this.flag.sendOwnerOperate = false;
      this.flag.sendBossIng = true;
      updateInvoiceStatus({
        Json: JSON.stringify({
          InvoiceTaskId: this.getInvoiceTaskID,
          expressName: this.sendBossForm.expressName,
          trackingNum: this.sendBossForm.expressNumber,
          Status: status,
        }),
      })
        .then((res) => {
          this.flag.sendBossDialog = false;
          this.flag.sendInvoice = true;
          this.flag.sendOwnerOperate = true;
        })
        .catch((res) => {
          this.uploadErrorList = res.data.invoices;
          this.flag.uploadErrorDialog = true;
        })
        .finally(() => {
          this.flag.sendBossIng = false;
        });
    },
    //打开地图
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillNumber ? item.WaybillNumber : item.WaybillID,
        searchWord: item.LicensePlate ? item.LicensePlate : item.CarNumber,
        stDate: item.Loading_time ? item.Loading_time : item.LoadingDatetime,
        edDate: item.Submission_time ? item.Submission_time : item.SignDatetime,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },

    //描述颜色
    getColor(row) {
      console.log("Row", row);
      let color = "#66b1ff";
      if (row.InvoiceDesc) {
        if (this.consign.InvoiceKind == "平台普票") {
          if (row.InvoiceDesc.length > 87) {
            color = "red";
          } else {
            color = "#606266";
          }
        } else {
          if (row.InvoiceDesc.length > 105) {
            color = "red";
          } else {
            color = "#606266";
          }
        }
      }

      // scope.row.InvoiceDesc &&
      //                   scope.row.InvoiceDesc.length <= 105
      //                     ? '#606266'
      //                     : scope.row.InvoiceDesc &&
      //                       scope.row.InvoiceDesc.length > 105
      //                     ? 'red'
      //                     : '#66b1ff',
      return color;
    },
  },
  created() {
    this.invoiceTaskID = this.getInvoiceTaskID;
    //查询货主是否付款了
    this.ownerPays();
  },
  computed: {
    ...mapGetters(["getInvoiceTaskID"]),
    currentTable1data() {
      //选中表格
      this.$nextTick(() => {
        if (
          this.currentTable1dataSelectedList.length != 0 &&
          this.currentTable1dataSelectedList[this.pagination1.page - 1]
            .length != 0
        ) {
          this.currentTable1dataSelectedList[this.pagination1.page - 1].forEach(
            (item) => {
              this.tableData1Total.forEach((el) => {
                if (el.WaybillNumber === item.WaybillNumber) {
                  this.$refs.currentTable1dataRef.toggleRowSelection(el, true);
                }
              });
            }
          );
        }
      });
      return this.tableData1Total.slice(
        this.pagination1.pagesize * (this.pagination1.page - 1),
        this.pagination1.pagesize * this.pagination1.page
      );
    },
    //正常发票分页
    invoiceImgList() {
      try {
        return this.tableData2Total[this.currentIndex].invoiceImgList.slice(
          this.pagination3.pagesize * (this.pagination3.page - 1),
          this.pagination3.pagesize * this.pagination3.page
        );
      } catch (error) { }
    },
    //特殊发票分页
    specialInvoiceList() {
      try {
        return this.tableData2Total[this.currentIndex].specialInvoiceList.slice(
          this.pagination4.pagesize * (this.pagination4.page - 1),
          this.pagination4.pagesize * this.pagination4.page
        );
      } catch (error) { }
    },
    //发票凭证分页
    voucherList() {
      try {
        return this.tableData2Total[this.currentIndex].voucherList.slice(
          this.pagination5.pagesize * (this.pagination5.page - 1),
          this.pagination5.pagesize * this.pagination5.page
        );
      } catch (error) { }
    },
    //电子发票分页
    electronInvoiceList() {
      try {
        return this.tableData2Total[
          this.currentIndex
        ].electronInvoiceList.slice(
          this.paginationElectron.pagesize * (this.paginationElectron.page - 1),
          this.paginationElectron.pagesize * this.paginationElectron.page
        );
      } catch (error) { }
    },
  },
  watch: {
    "flag.step"(val) {
      if (val == 2) {
        //获取发票面额
        this.getDenomination();
        //获取运单列表
        this.billsInfoDeatils();
      }
    },
  },
  components: {
    UploadImg,
    TXmap,
    Step,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/style/variable.scss";

.facilityBox {
  .autoInvoice {
    height: calc(100vh - 150px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .desc {
      font-size: 30px;
      font-weight: 700;
      margin-top: 30px;
    }
  }

  .pending {
    p {
      margin: 10px 0;
      text-align: center;
    }
  }

  .bodyArea {
    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      border-bottom: 1px solid $borderGray;
      padding: 20px 0;
      margin-bottom: 20px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }

    .one {
      .payed {
        p {
          text-align: center;
          margin: 10px 0;
        }
      }
    }

    .two {
      .topInfo {
        min-width: 1100px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .currentAllFacaValue {
          margin: 0 0 12px 150px;
          display: flex;
          align-items: center;

          span {
            margin: 0 0 4px 0 !important;
          }

          .box {
            padding: 0 15px;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            color: #606266;
            height: 40px;
            line-height: 40px;

            span {
              padding-right: 60px;

              &:last-child {
                padding-right: 0px;
              }
            }
          }
        }
      }

      .select {
        margin: 6px 0 16px;
      }

      .pagination1 {
        text-align: left;
        margin: 10px 0;
      }
    }

    .three {
      margin: 0 auto;

      .voucherWrap {
        display: flex;
        flex-wrap: wrap;
        width: 1100px;

        .item {
          width: 18%;
          margin: 16px 10px;
          position: relative;

          .delete {
            cursor: pointer;
            position: absolute;
            font-size: 30px;
            position: absolute;
            bottom: 30px;
            right: 30px;
            z-index: 9999;
          }

          p {
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: blue;
            cursor: pointer;
          }
        }
      }

      .uploadImgWrap {
        display: flex;
        flex-wrap: wrap;
        width: 1100px;
        min-height: 50px;

        .item {
          width: 18%;
          margin: 16px 10px;
          position: relative;
          display: flex;
          flex-direction: column;

          .delete {
            cursor: pointer;
            position: absolute;
            font-size: 30px;
            position: absolute;
            bottom: 30px;
            right: 30px;
            z-index: 9999;
          }

          span {
            width: 200px;
            text-align: center;
          }
        }
      }
    }

    .three,
    .four {
      margin: 0 auto;

      .settleBox {
        margin-bottom: 10px;
        padding: 10px 16px;
        background-color: #f0f0f0;
        color: #666;
      }

      .sumArea {
        display: flex;
        align-items: center;
        margin: 16px 0;

        p {
          width: 150px;
          margin-right: 10px;
          font-size: 20px;
          font-weight: 700;
        }

        .total {
          flex: 1;
          background: $primary;
          display: flex;
          flex-wrap: wrap;
          color: white;
          padding: 10px;
          border-radius: 5px;

          div {
            width: 20%;
          }
        }
      }
    }
  }
}

.invoice-con {
  position: absolute;
  width: 475px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #000;
}
</style>
<style lang="scss">
.supplierStepFourCmpt {
  .bodyArea {
    .two {
      .el-table .DisableSelection .cell .el-checkbox__inner {
        display: none !important;
        position: relative !important;
      }

      .el-table .DisableSelection .cell:before {
        content: "" !important;
        position: absolute !important;
      }
    }
  }
}

.invoicePagination {
  margin: 10px 0;
}

.preview-dialog {
  .el-dialog {
    margin-top: 9vh !important;
  }

  .el-dialog__header {
    padding: 16px 16px 0px;
  }

  .el-dialog__body {
    padding: 0px 16px;
  }

  .el-dialog__footer {
    padding: 16px;
  }
}

.preview-invoice-demo {
  .el-dialog {
    margin-top: 2vh !important;
  }

  .el-dialog__body {
    padding: 15px 20px;
  }
}

.invoice-dialog {
  .el-dialog__header {
    padding: 16px 20px 0px;
  }

  // .el-dialog__title {
  //   font-size: 20px;
  //   font-weight: bold;
  // }
  .el-dialog__header {
    padding: 16px;
  }

  .el-dialog__footer {
    padding: 0px 16px 16px 0px;
  }
}

.special-table {
  .vxe-table--empty-placeholder {
    top: 50px !important;
  }

  table {
    width: 100% !important;
  }

  th,
  td {
    width: auto !important;
  }
}

.update-special-table .vxe-table--empty-placeholder {
  top: 50px !important;
}

.invoicingDialog {
  height: calc(100vh - 150px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;

  .desc {
    font-size: 30px;
    font-weight: 700;
    margin-top: 30px;
  }
}
</style>
<style>
.bodyArea .three .uploadImgWrap .el-input__inner {
  width: 150px !important;
}
</style>
